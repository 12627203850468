<ion-card style="height: 330px;">
  <ion-card-header>
    <ion-card-title>{{'best_product' | translate}}</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <div class="row g-1">
      <div class="col-12">
        <div class="top-right flex-container-button-right">
          <div (click)="updateTimeframe('today')" class="selectionButton"
            [ngClass]="timeframe == 'today' ? 'selectionButton active' : 'selectionButton'" translate>
            today
          </div>
          <div (click)="updateTimeframe('yesterday')" class="selectionButton"
            [ngClass]="timeframe == 'yesterday' ? 'selectionButton active' : 'selectionButton'" translate>
            yesterday
          </div>
          <div class="selectionButton" (click)="updateTimeframe('last_week')"
            [ngClass]="timeframe == 'last_week' ? 'selectionButton active' : 'selectionButton'" translate>
            last_week
          </div>
          <div class="selectionButton" (click)="updateTimeframe('last_30_days')"
            [ngClass]="timeframe == 'last_30_days' ? 'selectionButton active' : 'selectionButton'" translate>
            last_30_days
          </div>
        </div>
      </div>
    </div>
    <div class="ion-text-center mt-5" *ngIf="isLoading">
      <ion-spinner class="mt-5"></ion-spinner>
    </div>
    <table class="table mt-5" *ngIf="!isLoading">
      <thead>
        <tr>
          <th scope="col" translate>Name</th>
          <th scope="col" translate>assignments</th>
          <th scope="col" translate>sales_volume_netto </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th> <span>Vehar<sup>&reg;</sup></span> direct</th>
          <td>{{ topProductsData.direct.count }}</td>
          <td>{{ topProductsData.direct.total.toFixed(2) }} &euro;</td>
        </tr>
        <tr>
          <th scope="row"> <span>Vehar<sup>&reg;</sup></span> auction</th>
          <td>{{ topProductsData.auction.count }}</td>
          <td>{{ topProductsData.auction.total.toFixed(2) }} &euro;</td>
        </tr>
        <tr>
          <th scope="row"> <span>Vehar<sup>&reg;</sup></span> anonymous</th>
          <td>{{ topProductsData.anonymous.count }}</td>
          <td>{{ topProductsData.anonymous.total.toFixed(2) }} &euro;</td>
        </tr>

      </tbody>
    </table>
  </ion-card-content>
</ion-card>