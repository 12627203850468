import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToMuchLdmOrToHeavyModalComponent } from './to-much-ldm-or-to-heavy-modal/to-much-ldm-or-to-heavy-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { selection } from 'd3';

export function createNewCrateFormGroup(individual: boolean): FormGroup {
  return new FormGroup({
    'quantity': new FormControl('1', Validators.required),
    'packaging': new FormControl(null, Validators.required),
    'length': new FormControl({ value: '120', disabled: individual ? false : true }, [Validators.required, Validators.min(0.1)]),
    'wide': new FormControl({ value: '80', disabled: individual ? false : true }, [Validators.required, Validators.min(0.1)]),
    'height': new FormControl('100', individual ? [Validators.required] : [Validators.required, Validators.min(0.1), Validators.max(240)]),
    'weight': new FormControl('300', individual ? [Validators.required] : [Validators.required, Validators.min(1), Validators.max(1000)]),
    'stackable': new FormControl(null)
  });
}

@Component({
  selector: 'app-crate-input',
  templateUrl: './crate-input.component.html',
  styleUrls: ['./crate-input.component.scss'],
})
export class CrateInputComponent implements OnInit {
  methodCalled = false;
  @Input()
  isIframe = false;
  @Input()
  isDirect = false;
  constructor(private dialog: MatDialog,
    private _ref: ChangeDetectorRef) { }

  options = [
    {
      name: 'custom_crate',
      length: 110,
      width: 80
    },
    {
      name: 'euro_pallet_80',
      length: 120,
      width: 80
    },
    {
      name: 'euro_pallet_100',
      length: 120,
      width: 100
    },
    {
      name: 'one_pallet_80',
      length: 120,
      width: 80
    },
    {
      name: 'one_pallet_120',
      length: 120,
      width: 120
    },
    {
      name: 'one_pallet_100',
      length: 120,
      width: 100
    },
    {
      name: 'chep_euro_pallet',
      length: 120,
      width: 80
    },
    {
      name: 'chep_part_pallet',
      length: 76,
      width: 51
    },
    {
      name: 'chep_quarter_pallet',
      length: 60,
      width: 40
    },
    {
      name: 'euro_grid_box',
      length: 124,
      width: 84
    },
    {
      name: 'dus_pallet',
      length: 80,
      width: 60
    },
    {
      name: 'cp1',
      length: 120,
      width: 100
    },
    {
      name: 'cp2',
      length: 120,
      width: 80
    },
    {
      name: 'cp3',
      length: 114,
      width: 114
    },
    {
      name: 'cp4',
      length: 130,
      width: 110
    },
    {
      name: 'cp5',
      length: 114,
      width: 76
    },
    {
      name: 'cp6',
      length: 120,
      width: 100
    },
    {
      name: 'cp7',
      length: 130,
      width: 110
    },
    {
      name: 'cp8',
      length: 114,
      width: 114
    },
    {
      name: 'cp9',
      length: 114,
      width: 114
    },
    {
      name: 'EH1',
      length: 120,
      width: 80
    },
    {
      name: 'ibc_case',
      length: 120,
      width: 110
    },
    {
      name: 'xl_package',
      length: 80,
      width: 40,
      height: 30,
      maxWeight: 40
    },
    {
      name: 'l_package',
      length: 60,
      width: 38,
      height: 25,
      maxWeight: 40
    },
    {
      name: 'm_package',
      length: 45,
      width: 30,
      height: 20,
      maxWeight: 40
    },
    {
      name: 's_package',
      length: 35,
      width: 20,
      height: 10,
      maxWeight: 40
    },
    {
      name: 'xs_package',
      length: 28,
      width: 20,
      height: 5,
      maxWeight: 40
    }

  ]

  @Input()
  group!: FormGroup;

  @Input()
  individual: boolean = false;

  @Input()
  crateExists = false;

  package_data = {
    packages: 1,
    loading_meter: 0.4,
    total_weight: 300
  };
  currentOption: any = this.options[0];

  ngOnInit() {
    const crate = this.group.get('crates') as FormArray;

    this.calculateTotal();


    if (!this.crateExists && crate.length == 0) {
      this.addCrateGroup();
    }
    this.group.controls['crates'].valueChanges.subscribe(() => {
      
      if (!this.methodCalled) {
        this.methodCalled = true;        
        this.calculateTotal();
      }
    });

    if (!this.isDirect) {
      this.options = [
        {
          name: 'custom_crate',
          length: 110,
          width: 80
        },
        {
          name: 'euro_pallet',
          length: 120,
          width: 80
        },
        {
          name: 'one_pallet',
          length: 120,
          width: 80
        },
        {
          name: 'chep_euro_pallet',
          length: 120,
          width: 80
        },
        {
          name: 'chep_part_pallet',
          length: 76,
          width: 51
        },
        {
          name: 'chep_quarter_pallet',
          length: 60,
          width: 40
        },
        {
          name: 'euro_grid_box',
          length: 124,
          width: 84
        },
        {
          name: 'dus_pallet',
          length: 80,
          width: 60
        },
        {
          name: 'CP',
          length: 120,
          width: 100
        },
        {
          name: 'EH',
          length: 120,
          width: 80
        },
        {
          name: 'ibc_case',
          length: 120,
          width: 110
        },
        {
          name: 'colli',
          length: 120,
          width: 100
        },
        {
          name: 'pipe',
          length: 100,
          width: 100
        },
        {
          name: 'package',
          length: 80,
          width: 80
        },
        {
          name: 'crate',
          length: 110,
          width: 80
        },

      ]
    }
  }

  public addCrateGroup() {
    const crate = this.group.get('crates') as FormArray;
    crate.push(createNewCrateFormGroup(this.individual));
  }

  public removeOrClearCrates(i: any = null) {
    const crates = this.group.get('crates') as FormArray;
    if (crates.length > 1) {
      if (i) {
        crates.removeAt(i);
        return;
      }
      crates.removeAt(crates.length - 1);
    } else {
      crates.reset();
    }
  }

  getControls() {
    return (this.group.get('crates') as FormArray).controls;
  }

  private calculateTotal() {
    this.package_data = {
      packages: 0,
      loading_meter: 0,
      total_weight: 0
    };

    (this.group.controls['crates'] as FormArray).controls.forEach(controls => {
      if (controls) {
        const packages = parseInt(controls.get('quantity')!.value, 10),
          loading_meter = ((controls.get('length')!.value / 100) * (controls.get('wide')!.value / 100) / 2.4) * Number(controls.value.quantity),
          weight = parseInt(controls.get('weight')!.value, 10) * Number(controls.value.quantity);
        this.package_data.packages += isNaN(packages) ? 0 : packages;
        this.package_data.loading_meter += isNaN(loading_meter) ? 0 : loading_meter;
        this.package_data.total_weight += isNaN(weight) ? 0 : weight;
      }
    });
    this.methodCalled = false;

    if ((this.package_data.total_weight > 24000 || this.package_data.loading_meter > 13.6) && !this.individual) {


      setTimeout(() => {
        this.showToMuchAlert();
      }, 100)
    }

    this.group.controls['loading_meter'].setValue(this.package_data.loading_meter)
    this.group.controls['weight'].setValue(this.package_data.total_weight);
  }

  showToMuchAlert() {
    if (this.individual) return;
    this.dialog.open(ToMuchLdmOrToHeavyModalComponent,
      { width: '400px' });
  }

  updateLengthAndWidth($event: any, index: number) {
    const value = $event;
    const selectedOption = this.options.filter((option: any) => option.name === value);
    this.currentOption = selectedOption[0];
    const fg: any = (this.group.get('crates') as FormArray).at(index) as FormGroup;
    if (this.currentOption.name == 'custom_crate') {
      fg.enable()
      fg.controls.weight.clearValidators()
      fg.controls.weight.setValidators([Validators.required, Validators.min(1)])
      if (this.isDirect) {
        fg.controls.length.setValidators([Validators.required, Validators.max(240)]);
        fg.controls.wide.setValidators([Validators.required, Validators.max(120)]);
      }
    } else {
      fg.controls.weight.setValidators([Validators.required, Validators.min(1), Validators.max(selectedOption[0].maxWeight ?? 1000)])
      fg.controls.weight.setValidators([Validators.required, Validators.min(1), Validators.max(1000)])
      fg.controls.length.setValue(selectedOption[0].length);
      fg.controls.wide.setValue(selectedOption[0].width);
      fg.controls.length.disable()
      fg.controls.wide.disable()
    }
    fg.controls.weight.updateValueAndValidity()

    const heightControl = fg.controls.height;
    if (selectedOption[0].height) {
      heightControl.setValue(selectedOption[0].height);
      heightControl.disable();
    } else heightControl.enable();

    const weightControl = fg.controls.weight;
    if (selectedOption[0].maxWeight) {
      weightControl.setValue('');
      weightControl.setValidators([Validators.required, Validators.min(1), Validators.max(selectedOption[0].maxWeight)])
    }

    this._ref.detectChanges();
  }
  checkIfToHeavy() {
    if (this.individual || !this.isDirect) return false;
    let isTooHeavy = false;
    this.getControls().forEach((control: any) => {
      if (control.value.weight > 1000) {
        isTooHeavy = true;
      }
    });
    return isTooHeavy;
  }

  checkIfToHigh() {
    if (this.individual || !this.isDirect)
      return false;
    let isTooHigh = false;
    this.getControls().forEach((control: any) => {
      if (control.value.height > 170) {
        isTooHigh = true;
      }
    });
    return isTooHigh;
  }
}
