import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-statistics-container',
  templateUrl: './statistics-container.component.html',
  styleUrls: ['./statistics-container.component.scss'],
})
export class StatisticsContainerComponent implements OnInit {

  @Input()
  path!: string;

  @Input()
  title!: string;

  @Input()
  icon!: string;

  @Input()
  queryParam = 'status=status_direct_in_dispo';

  data: number = 0;

  timeframe = 'today';

  isLoading = true;

  constructor(private _http: HttpClient) { }

  ngOnInit() {
    this.loadData();
  }

  updateTimeframe(timeframe: string) {
    this.timeframe = timeframe;
    this.loadData();
  }

  getTimeFrame() {
    switch (this.timeframe) {
      case 'tomorrow':
        return {
          start: dayjs().add(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs().add(1, 'day').endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
      case 'this_week':
        return {
          start: dayjs().add(1, 'day').startOf('week').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs().add(1, 'day').endOf('week').endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
      case 'next_week':
        return {
          start: dayjs().add(1, 'week').startOf('week').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs().add(1, 'week').endOf('week').endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
      default:
        return {
          start: dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
    }
  }

  async loadData() {
    this.isLoading = true;
    try {      
      const timeframe = this.getTimeFrame();
      this.data = (await this._http.get<any>(`${environment.api_url}/${this.path}?start=${timeframe.start}&end=${timeframe.end}${this.queryParam ? '&' + this.queryParam : ''}`).toPromise()).count;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

}
