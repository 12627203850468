<h1 mat-dialog-title translate *ngIf="data">insurance_exists_headline</h1>
<h1 mat-dialog-title translate *ngIf="!data">insurance_not_exists_headline</h1>
<div mat-dialog-content class="dialog-content">
  <p translate *ngIf="data">insurance_exists_body</p>
  <p translate *ngIf="!data">insurance_not_exists_body</p>
  <div class="mt-2" *ngIf="data">
    <table class="table">
      <thead>
        <tr>
          <th scope="col" translate>net_amount</th>
          <th scope="col" translate>vat_label</th>
          <th scope="col" translate>brutto_price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">{{ data.payPriceNet }} &euro;</th>
          <td>{{data.vatPayPrice }} &euro;</td>
          <td>{{data.payPriceTotal}} &euro;</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div mat-dialog-actions>
  <button *ngIf="data" mat-button (click)="cancel()">{{'cancel' | translate}}</button>
  <button *ngIf="!data" mat-button (click)="cancel()">{{'ok' | translate}}</button>
  <button mat-button *ngIf="data" (click)="addToOrder()">{{'add_insurance' | translate}}</button>
</div>