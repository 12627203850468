
<div class="container" align="center">
  <div class="row mt-4">
    <div class="col-6 col-lg-3">
      <div class="case-container" (click)="openNewView('general_request')">
        <div class="center-container" align="center">
          <img slot="start" src="assets/icon/8.png" width="50" />
          <div class="title" translate>
            general_request
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-3">
      <div class="case-container" (click)="openNewView('order_change')">
        <div class="center-container">
          <img slot="start" src="assets/icon/12.png" width="50" />
          <div class="title" translate>
            order_change
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-3">
      <div class="case-container" (click)="openNewView('late_cancellation')">
        <div class="center-container">
          <img slot="start" src="assets/icon/18.png" width="50" />
          <div class="title" translate>
            late_cancellation
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-3">
      <div class="case-container" (click)="openNewView('insurance_notification')">
        <div class="center-container">
          <img slot="start" src="assets/icon/22.png" width="50" />
          <div class="title" translate>
            insurance_notification
          </div>
        </div>
      </div>
    </div>
  </div>
</div>