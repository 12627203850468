import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Preferences } from '@capacitor/preferences';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { DateService } from 'src/app/services/date/date.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vehicle-compare',
  templateUrl: './vehicle-compare.component.html',
  styleUrls: ['./vehicle-compare.component.scss'],
})
export class VehicleCompareComponent implements OnInit {

  constructor(private _builder: FormBuilder, private _date: DateService, private _translate: TranslateService,
    private _loading: LoadingController, private _http: HttpClient
  ) { }
  showOverSizeAlert = false;

  firstPossible = false;
  seccondPossible = false;
  thirdPossible = false;
  fourthPossible = true;

  loading_kind_form = this._builder.group({
    unloading_kind: ['', [Validators.required]],
    loading_kind: ['', [Validators.required,]],
    car: [{ disabled: true, value: 'sprinter' }, [Validators.required]]
  });

  loading_kinds = ['loading_kind_sender', 'loading_kind_side', 'loading_kind_top', "loading_by_sender", "loading_top"];
  unloading_kinds = ['unloading_kind_sender', 'unloading_kind_top', 'unloading_lift', 'unloading_kind_side', "unloading_top"];
  cars = ['sprinter', 'with_edscha']


  @Input()
  stepper!: MatStepper;

  @Output()
  onBackClicked: EventEmitter<boolean> = new EventEmitter();

  @Output()
  vehicleSelected: EventEmitter<number> = new EventEmitter();
  @Input()
  vehiclePriceData: any = [];

  @Input()
  group: FormGroup | undefined

  plan3Range = '';
  ngAfterViewInit(): void {
    if (!this.stepper) {
      this.loading_kind_form.markAllAsTouched();
    }
  }
  ngOnInit(): void {

    this.getDatesForPlan3();
  }

  goBack() {
    if (this.stepper) {
      this.stepper.previous();
    } else {
      this.onBackClicked.emit(true);
    }

  }

  getDatesForPlan3() {
    const start = this._date.getStartDateForPlan3(this.findFirstAvailableDate(2)!);

    this.plan3Range = this._date.getRangeForPlan3(start)
  }


  findFirstAvailableDate(selectedPlan: any): Date | null {
    let date = dayjs().startOf('day');
    while (!this._date.validateStartDate(date.toDate(), selectedPlan, 0)) {
      date = date.add(1, 'day');
    }
    return date.toDate();
  }

  selectVehicle(size: number) {
    const data: any = {
      unloading_kind: this.loading_kind_form.controls['unloading_kind'].value,
      loading_kind: this.loading_kind_form.controls['loading_kind'].value,
      car: this.loading_kind_form.controls['car'].value,
      plan: size
    }
    this.vehicleSelected.emit(data);
    console.log("hallo", this.stepper);

    if (this.stepper instanceof MatStepper) {
      this.stepper.next();
    } else {
      this.storeAsSession();
    }


  }
  async storeAsSession() {
    const loadingMessage = await this._translate.get('loading_controller_text').toPromise();
    const loader = await this._loading.create({
      message: loadingMessage
    });

    await loader.present();

    this._http.post<any>(`${environment.api_url}/session`, this.group?.getRawValue()).subscribe((result: any) => {
      loader.dismiss();
      Preferences.set({ key: 'session', value: result.sessionId });

      // Öffne die URL im gleichen Tab
      window.location.href = `https://vehar-logistik.de/dashboard/order?sessionId=${result.sessionId}`;
    });
  }



  loadingKindChanged($event: any) {
    this.updatePlans();
    if ($event.value == 'loading_kind_side') {
      this.showOverSizeAlert = true;
    }

    if ($event.value == 'loading_kind_top') {
      this.showOverSizeAlert = true;
    }

    if ($event.value !== 'loading_kind_top' && $event.value !== 'loading_kind_side' && !(this.loading_kind_form.controls['unloading_kind'].value == 'unloading_kind_top' || this.loading_kind_form.controls['unloading_kind'].value == 'unloading_lift')) {
      this.showOverSizeAlert = false;
    }

    if ($event.value == 'loading_top' || (this.loading_kind_form.controls['unloading_kind'].value && this.loading_kind_form.controls['unloading_kind'].value == 'unloading_top')) {
      this.loading_kind_form.controls['car'].setValue('with_edscha');
    } else {
      this.loading_kind_form.controls['car'].setValue('sprinter')
    }
  }

  unloadingKindChanged($event: any) {

    if ($event.value == 'unloading_top' || (this.loading_kind_form.controls['loading_kind'].value && this.loading_kind_form.controls['loading_kind'].value == 'loading_top')) {
      this.loading_kind_form.controls['car'].setValue('with_edscha');
    } else {
      this.loading_kind_form.controls['car'].setValue('sprinter')
    }
    this.updatePlans();
    if ($event.value == 'unloading_kind_top') {
      this.showOverSizeAlert = true;
    }
    if ($event.value == 'unloading_lift') {
      this.showOverSizeAlert = true;
    }

    if ($event.value == 'unloading_lift') {
      this.showOverSizeAlert = true;
    }
    if ($event.value == 'unloading_kind_top') {
      this.showOverSizeAlert = true;
    }
    if ($event.value !== 'unloading_lift' && $event.value !== 'unloading_kind_top' && !(this.loading_kind_form.controls['loading_kind'].value == 'loading_kind_top' || this.loading_kind_form.controls['loading_kind'].value == 'loading_kind_side')) {
      this.showOverSizeAlert = false;
    }
  }

  updatePlans() {
    const loadingKind = this.loading_kind_form.controls['loading_kind'].value;
    const unloadingKind = this.loading_kind_form.controls['unloading_kind'].value;
    if ((loadingKind == 'loading_kind_top' || loadingKind == 'loading_by_sender') && (unloadingKind == 'unloading_lift' || unloadingKind == 'unloading_kind_side')) {
      this.firstPossible = true;
    } else {
      this.firstPossible = false;
    }
    if (loadingKind == 'loading_top' || unloadingKind == 'unloading_top') {
      this.seccondPossible = false;
      this.thirdPossible = false;
    } else {
      this.seccondPossible = true;
      this.thirdPossible = true;
    }

  }
}
