import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-genie-chat',
  templateUrl: './genie-chat.component.html',
  styleUrls: ['./genie-chat.component.scss'],
})
export class GenieChatComponent {
  message = '';
  @ViewChild('chat', { static: false }) private messagesContainer: ElementRef | undefined;

  constructor(private http: HttpClient) {
  }
  sendMessage() {
    this.messages.push({
      userId: "user",
      message: this.message,
      createdBy: "user"
    })
    this.isWriting = true;
    this.scrollToBotom();
    this.http.post<any>(`${environment.api_url}/chat`, {
      "message": this.message

    }).subscribe(result => {
      this.isWriting = false;
      this.messages.push({
        userId: "user",
        message: result.message,
        createdBy: "ai"
      })
    })
    this.message = '';

    setTimeout(() => {
      this.scrollToBotom();
      this.scrollToBotom();
    }, 2000)
    this.scrollToBotom();

  }
  scrollToBotom() {
    setTimeout(() => {
      try {
        this.messagesContainer!.nativeElement.scrollTop = this.messagesContainer!.nativeElement.scrollHeight;
      } catch (err) { }
    }, 500); // Ver
  }
  isWriting = false;
  messages: any[] = [
    {
      userId: "test",
      message: "Hey ich bin Vehar® geenie, wie kann ich dir helfen",
      createdBy: "ai"
    }
  ]
}
