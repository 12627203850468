<div id="scene">
  <div id="boat">
    <span class="window"></span>
    <span class="tower"></span>
    <span class="cargo"></span>
  </div>
  <div id="water">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <h1><span translate>loading</span> ...</h1>
</div>
