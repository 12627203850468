<h2 translate>required_files</h2>
<div class="container">
  <div class="row">
    <div class="col-12 col-xl-3">
      <app-drop-zone-file-upload (didUpload)="storeUrl($event)" (getBase64)="storeBase64($event)"
        [acceptFilter]="'application/pdf,image/png,image/jpg,image/jpeg'" class=""
        title="kravag_form"></app-drop-zone-file-upload>
    </div>
    <div class="col-12 col-xl-3">
      <app-drop-zone-file-upload (didUpload)="storeUrl($event)" (getBase64)="storeBase64($event)"
        [acceptFilter]="'application/pdf,image/png,image/jpg,image/jpeg'" class=""
        title="damage_image"></app-drop-zone-file-upload>
    </div>
    <div class="col-12 col-xl-3">
      <app-drop-zone-file-upload (didUpload)="storeUrl($event)" (getBase64)="storeBase64($event)"
        [acceptFilter]="'application/pdf,image/png,image/jpg,image/jpeg'" class=""
        title="damage_invoice"></app-drop-zone-file-upload>
    </div>
    <div class="col-12 col-xl-3">
      <app-drop-zone-file-upload [acceptFilter]="'application/pdf,image/png,image/jpg,image/jpeg'" class=""
        title="trade_invoice" (didUpload)="storeUrl($event)"
        (getBase64)="storeBase64($event)"></app-drop-zone-file-upload>
    </div>
    <div class="col-12 clearfix">
      <button class="float-stat" mat-button (click)="goBackToDetail()">{{'back' | translate}}</button>

      <ion-button (click)="sendFilesToInsurance()" color="secondary" class="float-end next"
        [disabled]="base64Data.length !== fileUrls.length">
        <ion-label translate>send_reclamation</ion-label>
        <ion-icon name="send-outline" slot="end"></ion-icon>
      </ion-button>
    </div>
  </div>
</div>