<div class="position-relative">
  <mat-icon class="close-icon" (click)="close()">close</mat-icon>
  <div class="container mt-4">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="item">
          <div class="heading" *ngIf="data.task_nr" translate>task_nr</div>
          <div class="heading" *ngIf="!data.task_nr" translate>offer_number</div>
          <div class="content" *ngIf="!data.task_nr">{{ data.offer_number }}</div>
          <div class="content" *ngIf="data.task_nr">{{ data.task_nr }}</div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="item">
          <div class="heading" translate>status</div>
          <div class="content">{{ data.status | translate}}</div>
        </div>
      </div>
      <div class="col-12">
        <div class="item mt-3" *ngIf="data.offer_number.startsWith('direct')">
          <div class="heading" translate>customer_detailview</div>
          <div class="content">{{ data.additional_information.delivery_kind | translate }}</div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="item" *ngIf="mail">
          <div class="heading" translate>offer_detail_contact_us</div>
          <div class="content">
            <a [href]="'mailto:'+this.mail+'?subject='+getOfferNr()">{{this.mail}}</a>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="item" *ngIf="data.total">
            <div class="heading" translate>freight_price</div>
            <div class="content">{{ data.total?.toFixed(2) }} &euro;</div>
          </div>
        </div>
        <div class="col-12 mt-4">
          <app-anonymous-auction-supplier-content *ngIf="data.groups" (reloadData)="reloadData($event)"
            [data]="this.data"></app-anonymous-auction-supplier-content>
          <app-supplier-direct-content *ngIf="data.product == 'Vehar® Direct'"
            [data]="this.data"></app-supplier-direct-content>
        </div>
        <div class="col-12 mt-2"
          *ngIf="data.convertedToDirect == true && data.status !== 'status_direct_draft' &&  !data.status.includes('auction') && !data.status.includes('anonymous') ">
          <ion-card>
            <ion-card-header>
              <ion-card-title translate>
                price
              </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <div class="container">
                <div class="row">
                  <div class="col-12 pt-2 pb-2" align="left">
                    <div align="right">
                      <div class="row" style="max-width: 400px;">
                        <div class="col-6 net_price" translate>
                          netto_price
                        </div>
                        <div class="col-6 net_price" align="right">
                          {{(data.ekPrice).toFixed(2)}} &euro;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ion-card-content>
          </ion-card>
        </div>
      </div>
    </div>
  </div>