<h2 mat-dialog-title translate>price_renegotiate</h2>
<mat-dialog-content class="mat-typography">
  <p *ngIf="!data.isSupplier" translate>price_renegotiate_description</p>
  <p *ngIf="data.isSupplier" translate>negotiate_text_logistic</p>
  <form class="mt-4" [formGroup]="group">
    <form [formGroup]="group">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label translate>desired_price</mat-label>
        <input matInput formControlName="price" type="number" />
        <mat-icon matSuffix>euro</mat-icon>
      </mat-form-field>
    </form>
    <i translate>additional_tax</i>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" [disabled]="loading">{{'cancel' | translate}}</button>
  <button mat-button [disabled]="group.invalid || loading" (click)="askForNegotiation()" cdkFocusInitial>
    <span>OK</span>
  </button>
</mat-dialog-actions>
<div class="spinner-container" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>