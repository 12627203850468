import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { MapsService } from 'src/app/services/maps/maps.service';
import { environment } from 'src/environments/environment';
import { BetTooLowDialogComponent } from '../../auction/bet-too-low-dialog/bet-too-low-dialog.component';
import { BetSuccessDialogComponent } from '../../auction/bet-success-dialog/bet-success-dialog.component';
import { BetDeleteDialogComponent } from '../../auction/bet-delete-dialog/bet-delete-dialog.component';
import { BetBuyNowComponent } from '../../auction/bet-buy-now/bet-buy-now.component';
import { BehaviorSubject } from 'rxjs';
import { AnonymousOfferCreationSuccessDialogComponent } from '../../anonymous-offer-creation-success-dialog/anonymous-offer-creation-success-dialog.component';
import { AnonymousOfferCreationFailedDialogComponent } from '../../anonymous-offer-creation-failed-dialog/anonymous-offer-creation-failed-dialog.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AskUserDialogComponent } from '../../ask-user-dialog/ask-user-dialog.component';
import { UserInformationDialogComponent } from '../../user-information-dialog/user-information-dialog.component';
import { DashboardPriceNegotiateComponent } from 'src/app/pages/dashboard/customer-dashboard/dashboard-price-negotiate/dashboard-price-negotiate.component';
import { AnonymousNegotiationFailedDialogComponent } from '../../anonymous-negotiation-failed-dialog/anonymous-negotiation-failed-dialog.component';
import { AnonymousNegotiationSuccessDialogComponent } from '../../anonymous-negotiation-success-dialog/anonymous-negotiation-success-dialog.component';

@Component({
  selector: 'app-anonymous-auction-supplier-content',
  templateUrl: './anonymous-auction-supplier-content.component.html',
  styleUrls: ['./anonymous-auction-supplier-content.component.scss'],
})
export class AnonymousAuctionSupplierContentComponent implements OnInit {

  userId = '';

  @Input()
  data: any;

  @Output()
  reloadData: EventEmitter<string> = new EventEmitter();

  timer = '';
  @ViewChild('map', { static: true }) mapElementRef!: ElementRef;
  googleMaps: any;
  distance: any;
  directionsService: any;
  directionsDisplay: any;
  bet: any;
  loading = false;
  oldbet: any;
  loadingBuyNow = false;
  existingOffer: any;
  isCancelLoading = false;
  loadingNegotiate = false;
  bestBet: number | undefined;
  buyNowPrice = new BehaviorSubject('');
  constructor(private renderer: Renderer2, private maps: MapsService,
    private _http: HttpClient, private dialog: MatDialog, private afAuth: AngularFireAuth,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.afAuth.authState.subscribe(user => {
      this.userId = user!.uid
    })
    if (this.data.product == 'Vehar® Auction') {
      this.checkPreviousBet();
      this.updateBets();
      this.getBestBet();
      setInterval(() => {
        this.updateBets();
      }, 2000)
      setInterval(() => {
        this.timer = this.getDifference();

      }, 1000);
    } else {
      this.checkIfOfferExists();
      this.updateOffers()
      setInterval(() => {
        this.updateOffers();
      }, 2000)
    }
  }
  updateOffers() {
    this._http.get<any>(`${environment.api_url}/anonymous/${this.data._id}/offers`).subscribe(result => {
      this.data.offers = result;
      this.checkIfOfferExists();
    })
  }

  async checkIfOfferExists() {
    const user = await this.afAuth.currentUser;
    if (this.data.offers?.filter((offer: any) => offer.createdBy == user?.uid)?.length > 0) {
      this.existingOffer = this.data.offers.filter((offer: any) => offer.createdBy == user?.uid)[0];
    }
  }

  updateBets() {
    this._http.get<any>(`${environment.api_url}/supplier/auction/${this.data._id}/bets`).subscribe(result => {
      this.data.bets = result;
      this.getBestBet();
    })
  }

  async checkPreviousBet() {
    this._http.get<any>(`${environment.api_url}/supplier/auction/${this.data._id}/bet`).subscribe(result => {
      if (result) {
        this.oldbet = result.ekPrice;
      }
    });
  }

  negotiate() {
    const dialogRef = this.dialog.open(DashboardPriceNegotiateComponent, {
      width: "400px",
      data: {
        anonymousData: this.data,
        offer: this.existingOffer,
        isSupplier: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.error) {
        this.dialog.open(AnonymousNegotiationFailedDialogComponent, {
          width: "400px",

        });
      } else if (result) {
        this.dialog.closeAll();
        const ref = this.dialog.open(UserInformationDialogComponent, {
          width: "400px", data: {
            headline: 'anonymous_success_headline_negotiate',
            body: 'anonymous_success_body_negotiate_supplier'
          }
        });
        ref.afterClosed().subscribe(result => {
          this.reloadData.emit(`${environment.api_url}/anonymous/${this.data._id}`)
        })
      }
    });
  }
  placeOffer() {
    this.loading = true;
    this._http.post<any>(`${environment.api_url}/anonymous/${this.data._id}/offer`, {
      price: this.bet
    }).subscribe(result => {
      this.loading = false;
      if (result.message == 'ok') {
        setTimeout(() => {
          this.afAuth.authState.subscribe(user => { 
            if(user){
              (window as any).gtag('event', 'conversion', {
                'send_to': 'AW-16564255960/4dKoCJ_54sUZENj5udo9',
                'transaction_id': `anymous-offer-${this.data.offer_number}-${user.uid}`
              });
            }
          });
          const ref = this.dialog.open(AnonymousOfferCreationSuccessDialogComponent, {
            width: "400px"
          });
          ref.afterClosed().subscribe(result => {
            this.reloadData.emit(`${environment.api_url}/anonymous/${this.data._id}`)
          })
        }, 100)
      }
    }, error => {
      this.loading = false;
      this.dialog.open(AnonymousOfferCreationFailedDialogComponent, {
        width: "400px"
      });
    })
  }


  deleteBet() {
    const dialogRef = this.dialog.open(BetDeleteDialogComponent, { data: this.data });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.message !== 'auction_already_expired') {
          this.oldbet = null;
          this.updateBets();
        }
      }
    })
  }

  revokeOffer() {
    const dialogRef = this.dialog.open(AskUserDialogComponent, {
      width: "400px", data: {
        headline: 'revoke_offer_headline',
        body: 'revoke_offer_body'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.revokeOfferInDB();
      }
    })
  }
  revokeOfferInDB() {
    this.isCancelLoading = true;
    this._http.put<any>(`${environment.api_url}/anonymous/${this.data._id}/offer/${this.existingOffer._id}/logistic/revoke`, {}).subscribe(result => {
      this.isCancelLoading = false;
      const ref = this.dialog.open(UserInformationDialogComponent, {
        width: "400px", data: {
          headline: 'revoke_success_headline',
          body: 'revoke_success_body'
        }
      });
      ref.afterClosed().subscribe(result => {
        this.isCancelLoading = false;
        this.reloadData.emit(`${environment.api_url}/anonymous/${this.data._id}`)
      })
    }, error => {
      this.isCancelLoading = false;
      const ref = this.dialog.open(UserInformationDialogComponent, {
        width: "400px", data: {
          headline: 'revoke_failed_headline',
          body: 'revoke_failed_body'
        }
      });
    })
  }

  async loadMap(index: number) {
    try {
      const elem = document.getElementById(`map-${index}`);
      const sourceLat = this.data.groups[index].pickup.location[0];
      const sourceLng = this.data.groups[index].pickup.location[1];
      const destLat = this.data.groups[index].deliver.location[0];
      const destLng = this.data.groups[index].deliver.location[1];

      let googleMaps: any = await this.maps.loadGoogleMaps();
      const mapEl = elem;
      const map = new googleMaps.Map(mapEl, {
        center: { lat: sourceLat, lng: sourceLng },
        disableDefaultUI: true,
        zoom: 10,
      });
      this.directionsService = new googleMaps.DirectionsService;
      this.directionsDisplay = new googleMaps.DirectionsRenderer;
      this.directionsDisplay = new googleMaps.DirectionsRenderer();
      const source_position = new googleMaps.LatLng(sourceLat, sourceLng);
      const destination_position = new googleMaps.LatLng(destLat, destLng);
      const source_marker = new googleMaps.Marker({
        map: map,
        position: source_position,
        animation: googleMaps.Animation.DROP
      });

      const destination_marker = new googleMaps.Marker({
        map: map,
        position: destination_position,
        animation: googleMaps.Animation.DROP,
      });

      source_marker.setMap(map);
      destination_marker.setMap(map);

      this.directionsDisplay.setMap(map);
      this.directionsDisplay.setOptions({
        polylineOptions: {
          strokeWeight: 3,
          strokeOpacity: 1,
          strokeColor: '#7b213e'
        },
        suppressMarkers: true
      });

      await this.drawPolyline(index);

      map.setCenter(source_position);
      this.renderer.addClass(mapEl, 'visible');
    } catch (e) {
    }
  }

  declineNegotiation() {
    const dialogRef = this.dialog.open(AskUserDialogComponent, {
      width: "400px", data: {
        headline: 'decline_negotiation_headline',
        body: 'decline_negotiation_body'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sendOfferDecline();
      }
    })
  }

  sendOfferDecline() {
    this.loadingNegotiate = true;
    this._http.put<any>(`${environment.api_url}/anonymous/${this.data._id}/offer/${this.existingOffer._id}/logistic/decline`, {}).subscribe(result => {
      this.reloadData.emit(`${environment.api_url}/anonymous/${this.data._id}`)
      setTimeout(() => {
        this.loading = false;
        const ref = this.dialog.open(UserInformationDialogComponent, {
          width: "400px", data: {
            headline: 'decline_negotiation_succeeded_headline',
            body: 'decline_negotiation_succeeded_body'
          }
        });
        ref.afterClosed().subscribe(result => {
          this.reloadData.emit(`${environment.api_url}/anonymous/${this.data._id}`)
        })
      }, 100)
    }, error => {
      this.dialog.open(UserInformationDialogComponent, {
        width: "400px", data: {
          headline: 'decline_negotiation_failed_headline',
          body: 'decline_negotiation_failed_body'
        }
      });

    })
  }

  drawPolyline(index: number) {
    const sourceLat = this.data.groups[index].pickup.location[0];
    const sourceLng = this.data.groups[index].pickup.location[1];
    const destLat = this.data.groups[index].deliver.location[0];
    const destLng = this.data.groups[index].deliver.location[1];
    const source = { lat: sourceLat, lng: sourceLng };
    const dest = { lat: destLat, lng: destLng };
    this.directionsService.route({
      origin: source,
      destination: dest,
      travelMode: 'DRIVING',
      provideRouteAlternatives: true
    }, (response: any, status: any) => {
      if (status === 'OK') {
        this.directionsDisplay.setDirections(response);
      }
    });
  }
  getBestBet() {

    if (this.data.maximumbet) {
      this.bestBet = this.findLowestBet(this.data.bets)?.ekPrice.toFixed(2).replace('.', ',');
      if (this.bestBet) {
        this.buyNowPrice.next(`${this.bestBet}`);
      } else {
        this.buyNowPrice.next(this.data.maximumbet_ek.toFixed(2).replace('.', ','));
      }
    } else {
      if (this.findLowestBet(this.data.bets)?.ekPrice.toFixed(2).replace('.', ',')) {
        this.bestBet = this.findLowestBet(this.data.bets)?.ekPrice.toFixed(2).replace('.', ',');
      }

    }

  }
  async renderMap($event: any) {
    await this.loadMap($event.index)
  }

  getType(shipment: any) {
    if (this.data.product == 'Vehar® Auction') {
      if (shipment.selectedTab == 0) {
        return 'auction_individual'
      } else if (shipment.selectedTab == 1) {
        return 'auction_loadingmetre'
      } else {
        return 'auction_cars'
      }
    } else {
      if (shipment.selectedTab == 0) {
        return 'individual_request';
      } else if (shipment.selectedTab == 1) {
        return 'request_based_loading_meter';
      }
      return 'request_based_vehicle_type';
    }
  }

  acceptOffer() {
    const dialogRef = this.dialog.open(AskUserDialogComponent, {
      width: "400px", data: {
        headline: 'accept_negotiated_offer_headline',
        body: 'accept_negotiated_offer_body'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.sendOfferAcceptance();
      }
    })
  }
  sendOfferAcceptance() {
    
    this._http.put<any>(`${environment.api_url}/anonymous/${this.data._id}/offer/${this.existingOffer._id}/logistic/accept`, {}).subscribe(result => {

      setTimeout(() => {
        this.loading = false;
        const ref = this.dialog.open(UserInformationDialogComponent, {
          width: "400px", data: {
            headline: 'acceptance_succeeded_headline',
            body: 'acceptance_succeeded_body'
          }
        });
        ref.afterClosed().subscribe(result => {
          this.reloadData.emit(`${environment.api_url}/anonymous/${this.data._id}`)
        })
      }, 100)

    }, error => {
      this.loading = false;
      this.dialog.open(UserInformationDialogComponent, {
        width: "400px", data: {
          headline: 'acceptance_failed_headline',
          body: 'acceptance_failed_body'
        }
      });
    })
  }

  async placeBet() {
    this.loading = true;
    this._http.post<any>(`${environment.api_url}/auction/${this.data._id}/bet`, {
      price: this.bet
    }).subscribe(result => {
      this.loading = false;
      if (result.message == 'ok') {
        this.oldbet = this.bet;
        this.data.bets.push({
          ekPrice: this.bet
        });

        this.bet == null;
        this.updateBets();
        this.afAuth.authState.subscribe(user => { 
          if(user){
            (window as any).gtag('event', 'conversion', {
              'send_to': 'AW-16564255960/eb1ICKL54sUZENj5udo9',
              'transaction_id': `auction-offer-${this.data.offer_number}-${user.uid}`
            });
          }
        });
        this.dialog.open(BetSuccessDialogComponent, { width: "400px" });
      } else {
        let data: any = {};
        if (result.message == 'auction_already_expired' || result.message == 'auction_already_set' ) {
          data = {
            isExpired: true
          }
        }
        this.dialog.open(BetTooLowDialogComponent, { data: data, width: "400px" });
      }
    })
  }


  getDifference(): string {
    const today = dayjs();
    const auctionDate = dayjs(this.data.offer_duration);
    const auctionDuration = auctionDate.diff(today);
    const diffMs = auctionDuration; // Millisekunden zwischen jetzt und Ablaufdatum
    const diffDays = Math.floor(diffMs / 86400000); // Tage
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // Stunden
    const diffMins = Math.floor((diffMs % 3600000) / 60000); // Minuten
    const diffSecs = Math.floor((diffMs % 60000) / 1000); // Sekunden
    if (diffDays <= 0 && diffHrs <= 0 && diffMins <= 0 && diffSecs <= 0) {
      return 'abgelaufen';
    }
    let result = '';
    if (diffDays > 0) {
      result += diffDays + ' Tage, ';
    }
    if (diffHrs > 0) {
      result += diffHrs + ' Stunden, ';
    }
    if (diffMins > 0) {
      result += diffMins + ' Minuten, ';
    }
    result += diffSecs + ' Sekunden';

    return result;
  }

  findLowestBet(bets: any[]): any | undefined {
    if (bets.length === 0) {
      return undefined; // Wenn das Array leer ist, gibt es kein höchstes Gebot.
    }
    bets.sort((a, b) => b.ekPrice - a.ekPrice);
    return bets[bets.length - 1];
  }

  buyBet() {
    this.loadingBuyNow = true;
    const dialogRef = this.dialog.open(BetBuyNowComponent, { data: this.data, width: "400px" });
    dialogRef.afterClosed().subscribe(result => {
      if (result.buy) {
        this._http.get<any>(`${environment.api_url}/supplier/auction/${this.data._id}/buynow`).subscribe(result => {
          this.loadingBuyNow = false;
          this.data.status = 'status_auction_supplier_set';
        }, error => {
          alert("Der Sofort Zuschlag konnte nicht durchgeführt werden. Bitte später erneut versuchen")
        })
      }
    })
  }
  includesOption(options: any[], option: any) {
    const index = options.findIndex((object: any) => object.name === option);
    return index !== -1;
  }

  getValueForAdditionalInformation(options: any[], option: string): any {
    const object = options.filter((object: any) => object.name === option);
    if (object.length == 1) {
      return object[0];
    }
    return null;
  }
}
