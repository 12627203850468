import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-insurance-result-modal',
  templateUrl: './insurance-result-modal.component.html',
  styleUrls: ['./insurance-result-modal.component.scss'],
})
export class InsuranceResultModalComponent {

  constructor(private dialogRef: MatDialogRef<InsuranceResultModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }



  cancel() {
    this.dialogRef.close();
 
  }
  addToOrder() {
    this.dialogRef.close({ confirmed: true })
  }
}