<ion-header>
  <ion-toolbar>
    <ion-title translate>{{headline! | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button translate (click)="close()">cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-content p-3">
  <p translate>{{body!| translate}}</p>
  <form [formGroup]="group">
    <mat-form-field appearance="outline" class="full-width mt-2">
      <mat-label translate>storno_state</mat-label>
      <mat-select formControlName="state">
        <mat-option *ngFor="let state of states" [value]="state">{{state | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label translate>reason_for_cancellation</mat-label>
      <mat-select formControlName="reason">
        <mat-option *ngFor="let reason of reasons" [value]="reason">{{reason | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width mt-2" appearance="outline">
      <mat-label translate>reason_for_cancellation</mat-label>
      <textarea matInput formControlName="message" style="min-height: 200px"></textarea>
    </mat-form-field>
  </form>

  <ion-button class="full-width mt-4" color="primary" [disabled]="group.invalid" (click)="cancelOrder()">
    <span translate>ok</span>
    <span *ngIf="isLoading"> <ion-spinner name="crescent"></ion-spinner>
    </span>
  </ion-button>
</div>