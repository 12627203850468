import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-saved-complaint',
  templateUrl: './saved-complaint.component.html',
  styleUrls: ['./saved-complaint.component.scss'],
})
export class SavedComplaintComponent implements OnInit {

  constructor(private modal: ModalController) { }
  @Input()
  insurance: any;
  ngOnInit() { }

}
