import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private router: Router, private _auth: AngularFireAuth) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve) => {
      this._auth.onAuthStateChanged((user: any) => {
        if (!user) {
          this.router.navigate(["/login"]);
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthVerificationGuard {
  constructor(private router: Router, private _auth: AngularFireAuth) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve) => {
      this._auth.onAuthStateChanged(async (user) => {
        if (!user) {
          this.router.navigate(["/login"]);
          resolve(false);
        } else {
          const idToken = await user?.getIdTokenResult();
          if (!idToken?.claims['isVerified']) {
            // this.router.navigate(["/dashboard/profile/verification"]);
            resolve(false);
          } else {
            resolve(true);
          }
        }
      });
    });
  }
}
