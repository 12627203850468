import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-supplier-auction-anonymous-detail',
  templateUrl: './supplier-auction-anonymous-detail.component.html',
  styleUrls: ['./supplier-auction-anonymous-detail.component.scss'],
})
export class SupplierAuctionAnonymousDetailComponent implements OnInit {
  mail: string | undefined;
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private _http: HttpClient,
    private afs: AngularFirestore,
    private bottomSheetRef: MatBottomSheetRef<SupplierAuctionAnonymousDetailComponent>,) { }

  customer = '';
  ngOnInit() {
    this.getMail();

  }

  close() {
    this.bottomSheetRef.dismiss(this.data);
  }


  getMail() {
    this._http.get<any>(`${environment.api_url}/user`).subscribe(result => {
      if (result?.favourite) {
        if (result.favourite.mail && result.favourite.mail !== 'no_pref') {
          this.mail = result.favourite.mail;
        } else {
          this.mail = 'info@vehar.de';
        }
      } else {
        this.mail = 'info@vehar.de';
      }
    })
  }
  reloadData(url: string) {
    this._http.get<any>(url).subscribe(result => {
      this.data = result;
    })
  }
  getOfferNr() {
    if (this.data.task_nr) {
      return `Auftrags-Nr: ${this.data.task_nr} | ${this.data.product}`

    } else {
      return `Angebots-Nr: ${this.data.offer_number} | ${this.data.product}`
    }
  }
}
