<h2 translate>your_details</h2>

<form [formGroup]="group">
  <div class="row pt-1">
    <div class="col-12 col-lg-12 mt-3">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label translate>damage_date</mat-label>
        <input matInput class="date-input" formControlName="damage_date" [matDatepicker]="pickupStartPicker">
        <mat-datepicker-toggle matSuffix [for]="pickupStartPicker"></mat-datepicker-toggle>
        <mat-datepicker #pickupStartPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-6 mt-3">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label translate>estimated_amount_of_damage</mat-label>
        <input matInput formControlName="estimated_amount_of_damage" type="number" />
        <span matTextSuffix>&euro;</span>
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-6 mt-3">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label translate>loading_volume</mat-label>
        <input matInput formControlName="loading_volume">
        <span matTextSuffix>kg</span>
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-6 mt-3">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label translate>damaged_items</mat-label>
        <textarea matInput formControlName="damaged_items"></textarea>
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-6 mt-3">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label translate>what_was_damaged</mat-label>
        <textarea matInput formControlName="what_was_damaged"></textarea>
      </mat-form-field>
    </div>

    <div class="col-12 clearfix">
      <button class="float-stat" mat-button (click)="goBackToSurvey()">{{'back' | translate}}</button>

      <ion-button (click)="createReclamation()" color="secondary" class="float-end next" [disabled]="group.invalid">
        <ion-label translate>next</ion-label>
        <ion-icon name="arrow-forward-outline" slot="end"></ion-icon>
      </ion-button>
    </div>
  </div>
</form>