<ion-card>
  <ion-card-header>
    <ion-card-title translate>
      easy_to_follow_stats_headline
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>

    <div class="row g-1">
      <div class="col-12">
        <div class="flex-container-button-right">
          <div (click)="changeSum('LDM')" class="selectionButton"
            [ngClass]="sum=='LDM' ? 'selectionButton blue-active' : 'selectionButton blue-inactive'" translate>
            LDM
          </div>
          <div (click)="changeSum('reclamations')" class="selectionButton"
            [ngClass]="sum=='reclamations' ? 'selectionButton blue-active' : 'selectionButton blue-inactive'"
            translate>
            reclamations
          </div>
          <div (click)="changeSum('pallet_exchange')" class="selectionButton"
            [ngClass]="sum=='pallet_exchange' ? 'selectionButton blue-active' : 'selectionButton blue-inactive'"
            translate>
            pallet_exchange
          </div>
          <div (click)="changeSum('KM')" class="selectionButton"
            [ngClass]="sum=='KM' ? 'selectionButton blue-active' : 'selectionButton blue-inactive'" translate>
            KM
          </div>
          <div (click)="changeSum('total_weight')" class="selectionButton"
            [ngClass]="sum=='total_weight' ? 'selectionButton blue-active' : 'selectionButton blue-inactive'" translate>
            total_weight
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="flex-container-button mt-sm-5">
          <div (click)="updateTimeframe(1)" class="selectionButton"
            [ngClass]="timeframe==1 ? 'selectionButton active' : 'selectionButton'" translate>
            last_two_months
          </div>
          <div (click)="updateTimeframe(2)" class="selectionButton"
            [ngClass]="timeframe==2 ? 'selectionButton active' : 'selectionButton'" translate>
            last_three_months
          </div>
          <div class="selectionButton" (click)="updateTimeframe(5)"
            [ngClass]="timeframe==5 ? 'selectionButton active' : 'selectionButton'" translate>
            last_six_months
          </div>
          <div class="selectionButton" (click)="updateTimeframe(11)"
            [ngClass]="timeframe==11 ? 'selectionButton active' : 'selectionButton'" translate>
            last_twelve_months
          </div>
        </div>
      </div>
      <div class="col-12 position-relative">
        <div class="flex-container-button">
          <div (click)="updateCriteria('total_sales')" class="selectionButton"
            [ngClass]="criteria=='total_sales' ? 'selectionButton active' : 'selectionButton'" translate>
            total_sales
          </div>
          <div (click)="updateCriteria('number_orders')" class="selectionButton"
            [ngClass]="criteria=='number_orders' ? 'selectionButton active' : 'selectionButton'" translate>
            number_orders
          </div>
        </div>
      </div>
    </div>
    <div class="full-width mt-4" style="margin-top: 100px !important;">
      <canvas id="salesChart" height="80px" #salesChart></canvas>
    </div>

    <ion-button (click)="exportData()"
      class="ms-3 mt-2">
      <ion-icon name="download-outline" class="me-2"></ion-icon>
      <span translate>csv_export</span>
    </ion-button>
  </ion-card-content>
</ion-card>