import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
const survrey = require('../../../../../assets/insurance.json');

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit {
  isLinear = false;
  tabs = [survrey];
  @ViewChild('stepper') myStepper: MatStepper | undefined;
  @Input()
  orderId: string | undefined;

  @Output()
  finalRiskGroup: EventEmitter<string> = new EventEmitter();

  @Output()
  goBack: EventEmitter<any> = new EventEmitter();

  constructor(private _translate: TranslateService, private _loading: LoadingController, private _http: HttpClient, private _alert: AlertController, private modal: ModalController) { }
  ngOnInit(): void {
    this.checkPreviousClaims();
  }
  async checkPreviousClaims() {
    const text = await this._translate.get('loading_controller_text').toPromise();
    const loading = await this._loading.create({
      message: text,
    });
    loading.present();
    this._http.get<any>(`${environment.api_url}/insurance/${this.orderId}`).subscribe(async insurance => {
      if (insurance) {
        this.modal.dismiss();
        const alert = await this._alert.create({
          header: await this._translate.get('already_claimed_order').toPromise(),
          message: await this._translate.get('already_claimed_order_detail').toPromise(),
          cssClass: 'logout-modal',

          buttons: [
            {
              text: 'OK',
              role: 'ok'
            },
          ]
        });
        await alert.present();
      }
      loading.dismiss();
    }, error => {
      loading.dismiss();
    });
  }
  updateCurrentQuestion(option: any) {
    if (option.subcat) {
      this.tabs.push(option.subcat)
      setTimeout(() => {
        this.myStepper!.next();
      }, 200);


    } else {
      this.finalRiskGroup.emit(option.value);
    };
  }
  stepperChanged($event: any) {
    if ($event.previouslySelectedIndex > $event.selectedIndex) {
      setTimeout(() => {
        this.tabs.splice($event.selectedIndex + 1)
      }, 200)

    }
  }
  goBackToFirstView(index: number) {
    if (index > 0) {
      this.myStepper!.previous();
    } else {
      this.goBack.emit();
    }
  }
}