import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MapsService } from 'src/app/services/maps/maps.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-completed-success-dialog',
  templateUrl: './order-completed-success-dialog.component.html',
  styleUrls: ['./order-completed-success-dialog.component.scss'],
})
export class OrderCompletedSuccessDialogComponent implements OnInit {
  mail: string | undefined;

  constructor(private modal: ModalController, private renderer: Renderer2, private maps: MapsService, private _http: HttpClient) { }
  @Input()
  id!: string;
  @Input()
  kind!: string;
  data: any
  directionsService: any;
  directionsDisplay: any;

  @ViewChild('map', { static: true }) mapElementRef!: ElementRef;
  googleMaps: any;

  ngOnInit() {
    this.getMail();
    this.loadOrderDetails()
  }

  loadOrderDetails() {
    
    
    let url = `${environment.api_url}/direct/${this.id}`;
    if (this.kind === 'anonymous') {
      url = `${environment.api_url}/anonymous/${this.id}`
    } else if (this.kind === 'auction') {
      url = `${environment.api_url}/auction/${this.id}`
    }
    console.log("HURE ", this.kind);
    
    this._http.get<any>(url).subscribe(result => {
      this.data = result;
      if (this.kind === 'direct') {
        console.log(this.data);
        
        this.loadMap()
      } else {
        
        this.loadMapAsOther();
      }

    })

  }


  async loadMapAsOther() {
    try {
      const elem = document.getElementById(`map`);

      const sourceLat = this.data.groups[0].pickup.location[0];
      const sourceLng = this.data.groups[0].pickup.location[1];
      const destLat = this.data.groups[0].deliver.location[0];
      const destLng = this.data.groups[0].deliver.location[1];

      let googleMaps: any = await this.maps.loadGoogleMaps();
      const mapEl = elem;
      const map = new googleMaps.Map(mapEl, {
        center: { lat: sourceLat, lng: sourceLng },
        disableDefaultUI: true,
        zoom: 10,
      });
      this.directionsService = new googleMaps.DirectionsService;
      this.directionsDisplay = new googleMaps.DirectionsRenderer;
      this.directionsDisplay = new googleMaps.DirectionsRenderer();
      const source_position = new googleMaps.LatLng(sourceLat, sourceLng);
      const destination_position = new googleMaps.LatLng(destLat, destLng);
      const source_marker = new googleMaps.Marker({
        map: map,
        position: source_position,
        animation: googleMaps.Animation.DROP
      });

      const destination_marker = new googleMaps.Marker({
        map: map,
        position: destination_position,
        animation: googleMaps.Animation.DROP,
      });

      source_marker.setMap(map);
      destination_marker.setMap(map);

      this.directionsDisplay.setMap(map);
      this.directionsDisplay.setOptions({
        polylineOptions: {
          strokeWeight: 3,
          strokeOpacity: 1,
          strokeColor: '#7b213e'
        },
        suppressMarkers: true
      });

      await this.drawPolylineAsOther();

      map.setCenter(source_position);
      this.renderer.addClass(mapEl, 'visible');
    } catch (e) {
      console.log(e);

    }
  }

  dismiss() {
    this.modal.dismiss();
  }
  ngAfterViewInit() {

  }

  async loadMap() {
    try {
      const elem = document.getElementById(`map`);

      const sourceLat = this.data.address.pickup.location[0];
      const sourceLng = this.data.address.pickup.location[1];
      const destLat = this.data.address.deliver.location[0];
      const destLng = this.data.address.deliver.location[1];

      let googleMaps: any = await this.maps.loadGoogleMaps();
      const mapEl = elem;
      const map = new googleMaps.Map(mapEl, {
        center: { lat: sourceLat, lng: sourceLng },
        disableDefaultUI: true,
        zoom: 10,
      });
      this.directionsService = new googleMaps.DirectionsService;
      this.directionsDisplay = new googleMaps.DirectionsRenderer;
      this.directionsDisplay = new googleMaps.DirectionsRenderer();

      const source_position = new googleMaps.LatLng(sourceLat, sourceLng);
      const destination_position = new googleMaps.LatLng(destLat, destLng);

      const source_marker = new googleMaps.Marker({
        map: map,
        position: source_position,
        animation: googleMaps.Animation.DROP
      });

      const destination_marker = new googleMaps.Marker({
        map: map,
        position: destination_position,
        animation: googleMaps.Animation.DROP,
      });

      source_marker.setMap(map);
      destination_marker.setMap(map);

      this.directionsDisplay.setMap(map);
      this.directionsDisplay.setOptions({
        polylineOptions: {
          strokeWeight: 3,
          strokeOpacity: 1,
          strokeColor: '#7b213e'
        },
        suppressMarkers: true
      });

      await this.drawPolyline();

      map.setCenter(source_position);
      this.renderer.addClass(mapEl, 'visible');
    } catch (e) {
    }
  }

  drawPolyline() {
    const sourceLat = this.data.address.pickup.location[0];
    const sourceLng = this.data.address.pickup.location[1];
    const destLat = this.data.address.deliver.location[0];
    const destLng = this.data.address.deliver.location[1];
    const source = { lat: sourceLat, lng: sourceLng };
    const dest = { lat: destLat, lng: destLng };
    this.directionsService.route({
      origin: source,
      destination: dest,
      travelMode: 'DRIVING',
      provideRouteAlternatives: true
    }, (response: any, status: any) => {
      if (status === 'OK') {
        this.directionsDisplay.setDirections(response);
        const directionsData = response.routes[0].legs[0];
        const duration = directionsData.duration.text;
      }
    });
  }

  drawPolylineAsOther() {
    const sourceLat = this.data.groups[0].pickup.location[0];
    const sourceLng = this.data.groups[0].pickup.location[1];
    const destLat = this.data.groups[0].deliver.location[0];
    const destLng = this.data.groups[0].deliver.location[1];
    const source = { lat: sourceLat, lng: sourceLng };
    const dest = { lat: destLat, lng: destLng };
    this.directionsService.route({
      origin: source,
      destination: dest,
      travelMode: 'DRIVING',
      provideRouteAlternatives: true
    }, (response: any, status: any) => {
      if (status === 'OK') {
        this.directionsDisplay.setDirections(response);
      }
    });
  }

  getMail() {
    this._http.get<any>(`${environment.api_url}/user`).subscribe(result => {
      if (result?.favourite) {
        if (result.favourite.mail && result.favourite.mail !== 'no_pref') {
          this.mail = result.favourite.mail;
        } else {
          this.mail = 'info@vehar.de';
        }
      } else {
        this.mail = 'info@vehar.de';
      }
    })
  }

  getOfferNr() {
    
    if(!this.data){
      return ""
    }
    if (this.data?.task_nr) {
      return `Auftrags-Nr: ${this.data.task_nr} | ${this.data.product}`

    } else {
      return `Angebots-Nr: ${this.data.offer_number} | ${this.data.product}`
    }
  }
}
