import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-checkout-auction-anonymous-completion-modal',
  templateUrl: './checkout-auction-anonymous-completion-modal.component.html',
  styleUrls: ['./checkout-auction-anonymous-completion-modal.component.scss'],
})
export class CheckoutAuctionAnonymousCompletionModalComponent implements OnInit {
  isLoading = false;
  constructor(private _http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<CheckoutAuctionAnonymousCompletionModalComponent>, private nav: NavController) { }

  ngOnInit() { }

  createOffer() {
    this.isLoading = true;
    let url = `${environment.api_url}/order/auction`;
    if (this.data.isAnonymous) {
      if (this.data.id) {
        url = `${environment.api_url}/order/anonymous/${this.data.id}`;
      } else {
    
        url = `${environment.api_url}/order/anonymous`;
      }
      (window as any).gtag('event', 'conversion', {
        'send_to': 'AW-16564255960/x9C1CNT34cUZENj5udo9',
        'transaction_id': `anonymous-${this.data.id}_${this.data.createdBy}`
      });
    } else {
      if (this.data.id) {
        url = `${environment.api_url}/order/auction/${this.data.id}`;
        (window as any).gtag('event', 'conversion', {
          'send_to': 'AW-16564255960/7CJ8COf74cUZENj5udo9',
          'transaction_id': `auction-${this.data.id}_${this.data.createdBy}`
        });
      }
    }
    this._http.post<any>(url, this.data.data).subscribe(result => {
      if (result.id) {
        setTimeout(() => {
          this.ref.close({ data: result.id });
        }, 300)

      }

      this.nav.navigateRoot('/dashboard/order');
    })
  }
}
