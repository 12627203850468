// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    firebase: {
      apiKey: "AIzaSyB38Rs0SzraPM8h6OW8yQ-lXWf9rxIUCeY",
      authDomain: "vehar-logistik-dev.firebaseapp.com",
      projectId: "vehar-logistik-dev",
      storageBucket: "vehar-logistik-dev.appspot.com",
      messagingSenderId: "300842660807",
      appId: "1:300842660807:web:01ee91104c48d882ab1fe2"
    },
    production: false,
    maps_key: "AIzaSyBAb1obdYwR3S-Uj7VmpqUECcrfQKSys-w",
    api_url: 'https://v1-gkzbwh3kva-ey.a.run.app',
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  