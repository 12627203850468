import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CancelOrderDialogComponent } from '../../cancel-order-dialog/cancel-order-dialog.component';

@Component({
    selector: 'app-complaints-modal',
    templateUrl: './complaints-modal.component.html',
    styleUrls: ['./complaints-modal.component.scss'],
})
export class ComplaintsModalComponent {
    goBackToKravag() {
        this.isKravagShowing = true;
        this.isTermsShowing = false;
    }
    showTerms() {
        this.isKravagShowing = false;
        this.isTermsShowing = true
    }

    fileUpload = false;
    @Input()
    order: any;
    insuranceId = '';
    kind: any;
    insured_risk = '';
    isSurveyShown = true;
    isTermsShowing = false;
    isKravagShowing = false;
    dataSet: any;

    constructor(private modal: ModalController, private _modal: ModalController,) { }

    dissmiss() {
        this._modal.dismiss()
    }

    didSend($event: any) {
        if ($event == 'mail sent') {
            this.modal.dismiss();
            return;
        }
        this.dataSet = $event;
        this.isKravagShowing = true;

    }

    updateKind(kind: string) {
        this.kind = kind;
        if (this.kind == 'late_cancellation') {
            this.openCancellation();
        }

    }
    async openCancellation() {
        this.modal.dismiss();
        const offer: any = this.order
        offer.rawData = this.order;

        const modal = await this._modal.create({
            component: CancelOrderDialogComponent,
            cssClass: 'auto-height',
            componentProps: {
                headline: 'cancel_auction',
                body: 'cancel_auction_text',
                offer: offer,
                isSupplier: false
            }
        });
        await modal.present();
    }

    close() {
        this.modal.dismiss();
    }

    dismissModal($event: string) {
        this.modal.dismiss();
    }

    getCollection(order: any): any {
        if (order.product == 'Vehar® Direct') {
            return 'direct-orders'
        } else if (order.product == 'Vehar® Anonymous') {
            return 'anonymous-orders'
        } else {
            return 'auction-orders'
        }
    }

    updateSurveyShown(event: string) {
        this.insured_risk = event;
        this.isSurveyShown = false;;
    }
    goToSurvey() {
        this.isSurveyShown = true;
    }

    goBackToForm() {
        this.fileUpload = false;
        this.isKravagShowing = false;
        this.isTermsShowing = false;
    }
    goBackToFirstView() {
        this.kind = null;
        this.insured_risk = '';
        this.isSurveyShown = true;
    }
    goBackToTerms() {
        this.isTermsShowing = true;
        this.fileUpload = false;
    }

    goToFiles($event: string) {
        this.fileUpload = true;
        this.insuranceId = $event;
        this.isTermsShowing = false;
    }
}
