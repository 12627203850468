<mat-stepper [linear]="true" #stepper (selectionChange)="stepperChanged($event)">
  <mat-step *ngFor="let tab of tabs; let i = index">
    <ng-template matStepLabel>{{tab.title | translate}}</ng-template>
    <div class="row mt-4">
      <div class="col-12 mt-1 mb-3">
        <h2>{{tab.title | translate}}</h2>
      </div>
      <div class="col-6 col-lg-3" *ngFor="let option of tab.options">
        <div class="case-container" (click)="updateCurrentQuestion(option)">
          <div class="title">
            {{option.title | translate}}
          </div>
        </div>
      </div>
    </div>
    <button class="mt-4" mat-button matStepperPrevious (click)="goBackToFirstView(i)">{{'back' | translate}}</button>

  </mat-step>
</mat-stepper>