<div class="p-4" mat-dialog-content translate>submit_request_popup</div>
<div class="mt-4 mb-2">
  <div align="center">
    <ion-button color="secondary" class="mt-2" (click)="createOffer()">
      <span translate>publish</span>
      <span *ngIf="isLoading">&nbsp; &nbsp;</span>
      <span *ngIf="isLoading"> <ion-spinner name="crescent"></ion-spinner>
      </span>
    </ion-button>
  </div>
</div>