<h2 translate>your_details</h2>

<form [formGroup]="group">
  <div class="row pt-1">
    <div class="col-12 mt-3">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label translate>message</mat-label>
        <textarea matInput formControlName="message"></textarea>
      </mat-form-field>
    </div>
    <div class="col-12 clearfix">
      <button class="float-stat" mat-button (click)="goBackToFirstView()">{{'back' | translate}}</button>

      <ion-button color="secondary" class="float-end next" [disabled]="group.invalid" (click)="send()">
        <ion-label translate>submit</ion-label>
        <ion-icon name="send-outline" slot="end"></ion-icon>
      </ion-button>
    </div>
  </div>
</form>