import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { MapsService } from 'src/app/services/maps/maps.service';
import { AnonymousOfferDenyConfirmDialogComponent } from '../../anonymous-offer-deny-confirm-dialog/anonymous-offer-deny-confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AnonymousOfferAcceptDialogComponent } from '../../anonymous-offer-accept-dialog/anonymous-offer-accept-dialog.component';
import { AnonymousOfferAcceptSuccessDialogComponent } from '../../anonymous-offer-accept-success-dialog/anonymous-offer-accept-success-dialog.component';
import { DashboardPriceNegotiateComponent } from 'src/app/pages/dashboard/customer-dashboard/dashboard-price-negotiate/dashboard-price-negotiate.component';
import { AnonymousNegotiationSuccessDialogComponent } from '../../anonymous-negotiation-success-dialog/anonymous-negotiation-success-dialog.component';
import { AnonymousNegotiationFailedDialogComponent } from '../../anonymous-negotiation-failed-dialog/anonymous-negotiation-failed-dialog.component';
import { UserInformationDialogComponent } from '../../user-information-dialog/user-information-dialog.component';
import { CheckoutCompletionModalComponent } from '../../checkout-completion-modal/checkout-completion-modal.component';


@Component({
    selector: 'app-anonymous-auction-content',
    templateUrl: './anonymous-auction-content.component.html',
    styleUrls: ['./anonymous-auction-content.component.scss'],
})
export class AnonymousAuctionContentComponent implements OnInit {

    @Input()
    data: any;
    displayedColumns: string[] = ['selection', 'oldprice', 'price', 'date', 'status'];
    @ViewChild('map', { static: true }) mapElementRef!: ElementRef;
    googleMaps: any;
    distance: any;
    directionsService: any;
    directionsDisplay: any;
    selection = new SelectionModel<any>(true, []);
    timer = '';
    insuranceLoading = false;
    @Output()
    reloadAnonymousOrder: EventEmitter<string> = new EventEmitter();

    constructor(private renderer: Renderer2, private maps: MapsService,
        private dialog: MatDialog, private _translate: TranslateService, private _loading: LoadingController,
        private _http: HttpClient) { }

    ngOnInit() {
        setInterval(() => {
            this.timer = this.getDifference();
        }, 1000);
    }
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.data.offers.forEach((row: any) => this.selection.select(row));
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.data.offers.length;
        return numSelected === numRows;
    }

    getMaximumBet() {
        return Number(this.data.maximumbet).toFixed(2).replace(".", ",");
    }

    async loadMap(index: number) {
        try {
            const elem = document.getElementById(`map-${index}`);
            const sourceLat = this.data.groups[index].pickup.location[0];
            const sourceLng = this.data.groups[index].pickup.location[1];
            const destLat = this.data.groups[index].deliver.location[0];
            const destLng = this.data.groups[index].deliver.location[1];

            let googleMaps: any = await this.maps.loadGoogleMaps();
            const mapEl = elem;
            const map = new googleMaps.Map(mapEl, {
                center: { lat: sourceLat, lng: sourceLng },
                disableDefaultUI: true,
                zoom: 10,
            });
            this.directionsService = new googleMaps.DirectionsService;
            this.directionsDisplay = new googleMaps.DirectionsRenderer;
            this.directionsDisplay = new googleMaps.DirectionsRenderer();
            const source_position = new googleMaps.LatLng(sourceLat, sourceLng);
            const destination_position = new googleMaps.LatLng(destLat, destLng);
            const source_marker = new googleMaps.Marker({
                map: map,
                position: source_position,
                animation: googleMaps.Animation.DROP
            });

            const destination_marker = new googleMaps.Marker({
                map: map,
                position: destination_position,
                animation: googleMaps.Animation.DROP,
            });

            source_marker.setMap(map);
            destination_marker.setMap(map);

            this.directionsDisplay.setMap(map);
            this.directionsDisplay.setOptions({
                polylineOptions: {
                    strokeWeight: 3,
                    strokeOpacity: 1,
                    strokeColor: '#7b213e'
                },
                suppressMarkers: true
            });

            await this.drawPolyline(index);

            map.setCenter(source_position);
            this.renderer.addClass(mapEl, 'visible');
        } catch (e) {
        }
    }

    drawPolyline(index: number) {
        const sourceLat = this.data.groups[index].pickup.location[0];
        const sourceLng = this.data.groups[index].pickup.location[1];
        const destLat = this.data.groups[index].deliver.location[0];
        const destLng = this.data.groups[index].deliver.location[1];
        const source = { lat: sourceLat, lng: sourceLng };
        const dest = { lat: destLat, lng: destLng };
        this.directionsService.route({
            origin: source,
            destination: dest,
            travelMode: 'DRIVING',
            provideRouteAlternatives: true
        }, (response: any, status: any) => {
            if (status === 'OK') {
                this.directionsDisplay.setDirections(response);
                const directionsData = response.routes[0].legs[0];
                const duration = directionsData.duration.text;
            }
        });
    }

    async renderMap($event: any) {
        await this.loadMap($event.index);
    }

    async negotiatePrice() {
        const dialogRef = this.dialog.open(DashboardPriceNegotiateComponent, {
            width: "400px",
            data: {
                anonymousData: this.data,
                offer: this.selection.selected[0],
                isSupplier: false
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result?.error) {
                this.dialog.open(AnonymousNegotiationFailedDialogComponent, {
                    width: "400px",

                });
            } else if (result) {
                this.getAnoymousInformation();
                this.selection.clear();
                this.dialog.open(AnonymousNegotiationSuccessDialogComponent, {
                    width: "400px",
                    data: {
                        anonymousData: this.data,
                        offer: this.selection.selected[0]
                    }
                });
            }
        });
    }

    getType(shipment: any) {
        if (this.data.product == 'Vehar® Auction') {
            if (shipment.selectedTab == 0) {
                return 'auction_individual';
            } else if (shipment.selectedTab == 1) {
                return 'auction_loadingmetre';
            } else {
                return 'auction_cars';
            }
        } else {
            if (shipment.selectedTab == 0) {
                return 'individual_request';
            } else if (shipment.selectedTab == 1) {
                return 'request_based_loading_meter';
            }
            return 'request_based_vehicle_type';
        }

    }

    acceptOffer() {
        const dialogRef = this.dialog.open(AnonymousOfferAcceptDialogComponent, {
            width: "400px"
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                
                this.data.offerId = this.selection.selected[0]._id;
                const dialog = this.dialog.open(
                    CheckoutCompletionModalComponent,
                    {
                        data: this.data
                    }
                )
                dialog.afterClosed().subscribe(async (result: any) => {
                    if (result.data) {
                        
                        this.reloadAnonymousOrder.emit(this.data._id);
                    }
                })
            }
        });

    }

    denyOffers() {
        const dialogRef = this.dialog.open(AnonymousOfferDenyConfirmDialogComponent, {
            width: "400px"
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.denySelectedOffers();
            }
        });
    }
    async denySelectedOffers() {
        const text = await this._translate.get('loading_controller_text').toPromise();
        const loading = await this._loading.create({
            message: text,
        });
        loading.present();

        await Promise.all(this.selection.selected.map((item: any) => {
            this._http.put<any>(`${environment.api_url}/anonymous/${item._id}/offer/customer/decline`, {}).toPromise();
        }));
        loading.dismiss();
        this.reloadAnonymousOrder.emit(this.data._id);
    }

    getDifference(): string {
        const today = dayjs();
        const auctionDate = dayjs(this.data.offer_duration);
        const auctionDuration = auctionDate.diff(today);
        const diffMs = auctionDuration; // Millisekunden zwischen jetzt und Ablaufdatum
        const diffDays = Math.floor(diffMs / 86400000); // Tage
        const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // Stunden
        const diffMins = Math.floor((diffMs % 3600000) / 60000); // Minuten
        const diffSecs = Math.floor((diffMs % 60000) / 1000); // Sekunden
        if (diffDays <= 0 && diffHrs <= 0 && diffMins <= 0 && diffSecs <= 0) {
            return 'abgelaufen';
        }
        let result = '';
        if (diffDays > 0) {
            result += diffDays + ' Tage, ';
        }
        if (diffHrs > 0) {
            result += diffHrs + ' Stunden, ';
        }
        if (diffMins > 0) {
            result += diffMins + ' Minuten, ';
        }
        result += diffSecs + ' Sekunden';

        return result;
    }

    includesOption(options: any[], option: any) {
        const index = options.findIndex((object: any) => object.name === option);
        return index !== -1;
    }

    getValueForAdditionalInformation(options: any[], option: string): any {
        const object = options.filter((object: any) => object.name === option);
        if (object.length == 1) {
            return object[0];
        }
        return null;
    }
    getAnoymousInformation() {
        setTimeout(() => {
            this._http.get<any>(`${environment.api_url}/anonymous/${this.data._id}`).subscribe(result => {
                this.data = result;
            })
        }, 100)
    }


    async downloadInsuranceDocs(insurance: any): Promise<void> {
        this.insuranceLoading = true;
        this._http.get<any>(`${environment.api_url}/insurance/${insurance.insuranceId}/documents`).subscribe(response => {
            this.insuranceLoading = false;
            if (response.status === 'success' && response.documents) {
                response.documents.forEach((document: any) => {
                    if (document.data) {
                        const pdfBlob = this.base64ToBlob(document.data, 'application/pdf');
                        this.saveBlob(pdfBlob, document.filename);
                    }
                });
            } else {
                this.showErrorDialog();
            }
        }, error => {
            this.insuranceLoading = false;
            this.showErrorDialog();
        });
    }
    showErrorDialog() {
        this.dialog.open(UserInformationDialogComponent, {
            width: "400px", data: {
                headline: 'no_insurance_docs_headline',
                body: 'no_insurance_docs_body'
            }
        });
    }

    private base64ToBlob(base64: string, mimeType: string): Blob {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    }

    private saveBlob(blob: Blob, filename: string): void {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.href = url;
        link.download = filename;
        link.click();

        URL.revokeObjectURL(url);
    }
}
