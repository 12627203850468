<ion-header>
  <ion-toolbar color="primary">
    <ion-title translate>reclamations</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()" shape="circle">
        <ion-icon name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <app-first-view (kindSet)="updateKind($event)" *ngIf="!kind"></app-first-view>
  <app-survey [orderId]="this.order._id" (goBack)="goBackToFirstView()"
    *ngIf="kind && kind == 'insurance_notification' && isSurveyShown"
    (finalRiskGroup)="updateSurveyShown($event)"></app-survey>
  <app-details (goBack)="goToSurvey()" [collection]="getCollection(order)" (didSend)="didSend($event)"
    [insurance_risk]="insured_risk"
    *ngIf="!isSurveyShown&& kind && kind == 'insurance_notification' && !fileUpload && !isKravagShowing && !isTermsShowing"></app-details>
  <app-email-form [kind]="this.kind" [offer_number]="this.order.offer_number" (goBack)=" goBackToFirstView()" (didSend)="this.dissmiss()"
    *ngIf="kind && (kind == 'general_request' || kind == 'order_change')" ></app-email-form>
  <app-download-kravag-thing *ngIf="isKravagShowing"   (goBack)="goBackToForm()" (kravagDownloaded)="this.showTerms()"></app-download-kravag-thing>
  <app-accept-legal-notice (termsAccepted)="goToFiles($event)" [insuranceDetails]="this.dataSet" [orderId]="order._id"
    (goBack)="goBackToKravag()" *ngIf="isTermsShowing"></app-accept-legal-notice>
  <app-upload-files (goBack)="goBackToTerms()" [insuranceId]="insuranceId" (filesUploaded)="dismissModal($event)"
    *ngIf="fileUpload"></app-upload-files>
</ion-content>