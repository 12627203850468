import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-first-view',
  templateUrl: './first-view.component.html',
  styleUrls: ['./first-view.component.scss'],
})
export class FirstViewComponent implements OnInit {


  @Output()
  kindSet: EventEmitter<string> = new EventEmitter();

  openNewView(kind: string) {
    this.kindSet.emit(kind);
  }

  constructor() { }

  ngOnInit() { }

}
