<ion-card class="dashboard-welcome">
  <ion-card-content>
    <div class="row g-1">
      <div class="top-right flex-container-button-right mt-2">
        <div (click)="updateTimeframe('today')" class="selectionButton"
          [ngClass]="timeframe == 'today' ? 'selectionButton active' : 'selectionButton'" translate>
          today
        </div>
        <div (click)="updateTimeframe('tomorrow')" class="selectionButton"
          [ngClass]="timeframe == 'tomorrow' ? 'selectionButton active' : 'selectionButton'" translate>
          tomorrow
        </div>
        <div class="selectionButton" (click)="updateTimeframe('this_week')"
          [ngClass]="timeframe == 'this_week' ? 'selectionButton active' : 'selectionButton'" translate>
          this_week
        </div>
        <div class="selectionButton" (click)="updateTimeframe('next_week')"
          [ngClass]="timeframe == 'next_week' ? 'selectionButton active' : 'selectionButton'" translate>
          next_week
        </div>
      </div>
    </div>
    <div class="ion-text-center w-100 mt-5" *ngIf="isLoading">
      <ion-spinner class="mt-3"></ion-spinner>
    </div>
    <div class="row mt-5" *ngIf="!isLoading">
      <div class="col-8">
        <div class="number">{{data}}</div>
        <div class="statistics-headline" translate>{{title}}</div>
      </div>
      <div class="col-4" align="center">
        <ion-icon [name]="icon" class="stat-icon" color="primary"></ion-icon>
      </div>
    </div>
  </ion-card-content>
</ion-card>