import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anonymous-negotiation-failed-dialog',
  templateUrl: './anonymous-negotiation-failed-dialog.component.html',
  styleUrls: ['./anonymous-negotiation-failed-dialog.component.scss'],
})
export class AnonymousNegotiationFailedDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
