import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { countryListAllIsoData } from 'src/app/data/countries';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-address-import-dialog',
  templateUrl: './address-import-dialog.component.html',
  styleUrls: ['./address-import-dialog.component.scss'],
})
export class AddressImportDialogComponent implements OnInit {

  @Input()
  content!: string;

  isLoading = true;
  progressValue = 0;
  totalItems = 0;
  importedItems = 0;
  skippedItems = 0;

  elements: any[] = [];

  constructor(private _http: HttpClient,
    private _modal: ModalController) { }

  ngOnInit() {
    setTimeout(() => {
      this.parseContent();
    }, 800);
  }

  parseContent() {
    let rows: any[] = this.content.split('\n');
    const headers = rows.shift().split(';');

    rows = rows.map(row => {
      return row.split(';')
    }).map(row => {
      if (row?.length < 5) {
        return null;
      }
      let item: any = {};
      headers.forEach((header: string, index: number) => {
        item[header.replace(/\r/g, '')] = (row[index]?.replace(/\r/g, '') ?? '').replace('(', '').replace(')', '');
      });
      return item;
    }).filter(row => row);

    this.totalItems = rows.length;
    this.elements = rows;

    this.importNext();
  }

  closePopup() {
    setTimeout(() => {
      this._modal.dismiss();
    }, 800);
  }

  calculateProgress() {
    let progress = Math.floor(this.totalItems / (this.skippedItems + this.importedItems));
    if (progress == Infinity) {
      progress = 0;
    }
    this.progressValue = progress;
  }

  async importNext() {
    if (this.elements.length == 0) {
      this.closePopup();
      return;
    }

    const address = this.elements[0];
    if (!this.checkIfValidRow(address)) {
      this.elements.shift();
      this.skippedItems++;
    } else {
      try {
        await this._http.post<any>(`${environment.api_url}/additionaladdress`, {
          companyname: address.companyname,
          contact: address.contact,
          street: address.street,
          zipcode: address.zipcode,
          city: address.city,
          country: address.country.toUpperCase(),
          housenumber: address.housenumber,
          email: address.email,
          phone: address.phone,
          additionalInfos: address?.additionalInfos ?? ''
        }).toPromise();
        this.elements.shift();
        this.importedItems++;
      } catch (error) {
        console.error(error);
        this.elements.shift();
        this.skippedItems++;
      }
    }

    this.calculateProgress();
    setTimeout(() => {
      this.importNext();
    }, 200);
  }

  checkIfValidRow(row: any) {
    if (row.street && row.housenumber && row.zipcode && row.city && row.country) {
      const countries = countryListAllIsoData;
      if (countries.find(country => country.code == row.country.toUpperCase())) {
        return true;
      } else {
      }
    }
    return false;
  }

}
