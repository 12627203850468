<h1 mat-dialog-title translate>select_payment</h1>
<div class="mt-4">
  <ion-skeleton-text *ngIf="claimsLoading" [animated]="true" style="width:100%;height: 30px;"
    class="mt-2"></ion-skeleton-text>
  <ion-skeleton-text *ngIf="claimsLoading" [animated]="true" style="width: 100%;height: 30px;"
    class="mt-2"></ion-skeleton-text>
  <ion-skeleton-text *ngIf="claimsLoading" [animated]="true" style="width: 100%;height: 30px;"
    class="mt-2"></ion-skeleton-text>
  <mat-accordion *ngIf="!claimsLoading">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title translate>prepayment</mat-panel-title>
      </mat-expansion-panel-header>
      <p translate>prepayment_description</p>
      <div class="clearfix">
        <ion-button color="secondary" class="float-end" (click)="payVorab()" [disabled]="isLoading">
          <span translate>pay_before</span>
          <span *ngIf="isLoading">&nbsp; &nbsp;</span>
          <span *ngIf="isLoading"> <ion-spinner name="crescent"></ion-spinner>
          </span>
        </ion-button>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title translate>other_payment</mat-panel-title>
      </mat-expansion-panel-header>
      <p translate>other_payment_description</p>

      <div class="clearfix">
        <ion-button class="float-end" color="primary" [disabled]="isLoading2" (click)="payWithStripe()">
          <span translate>pay_now</span>
          <span *ngIf="isLoading2">&nbsp; &nbsp;</span>
          <span *ngIf="isLoading2"> <ion-spinner name="crescent"></ion-spinner>
          </span>
        </ion-button>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="isInvoiceAllowed && !claimsLoading">
      <mat-expansion-panel-header>
        <mat-panel-title translate>invoice_payment</mat-panel-title>
      </mat-expansion-panel-header>
      <p translate>invoice_payment_description</p>

      <div class="clearfix">
        <ion-button color="secondary" class="float-end" [disabled]="isLoading" (click)="payWithInvoice()">
          <span translate>by_invoice</span>
          <span *ngIf="isLoading">&nbsp; &nbsp;</span>
          <span *ngIf="isLoading"> <ion-spinner name="crescent"></ion-spinner>
          </span>
        </ion-button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>