import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  causeOfDamages = [
    {
      name: "lightning",
      id: "BL"
    },
    {
      name: "break_in",
      id: "EI"
    },
    {
      name: "vehicle_impact",
      id: "FA"
    },
    {
      name: "fire",
      id: "FE"
    },
    {
      name: "glas",
      id: "GL"
    },
    {
      name: "water",
      id: "LE"
    },
    {
      name: "other",
      id: "SO"
    },
    {
      name: "storm",
      id: "ST"
    },
    {
      name: "high_voltage",
      id: "UE"
    }
  ]

  @Input()
  insurance_risk: string = '';

  constructor(private _builder: FormBuilder) { }

  ngOnInit() {
  }

  @Input()
  collection: string | undefined

  @Output()
  didSend: EventEmitter<any> = new EventEmitter();

  @Output()
  goBack: EventEmitter<any> = new EventEmitter();

  group: FormGroup = this._builder.group({
    damage_date: [null, Validators.required],
    estimated_amount_of_damage: [null, Validators.required],
    damaged_items: [, Validators.required],
    loading_volume: [, Validators.required],
    what_was_damaged: [, Validators.required]
  });

  async createReclamation() {
    const data = this.group.value;
    data.insured_risk = this.insurance_risk
    data.collection = this.collection;
    this.didSend.emit(data);
  }
  goBackToSurvey() {
    this.goBack.emit();
  }
}
