import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accept-legal-notice',
  templateUrl: './accept-legal-notice.component.html',
  styleUrls: ['./accept-legal-notice.component.scss'],
})
export class AcceptLegalNoticeComponent implements OnInit {
  acceptedTerms = false;
  constructor(private _translate: TranslateService, private _loading: LoadingController,
    private _http: HttpClient, private _toast: ToastController) { }

  @Input()
  insuranceDetails!: any;

  @Input()
  orderId!: string;

  @Output()
  termsAccepted: EventEmitter<string> = new EventEmitter();

  @Output()
  goBack: EventEmitter<any> = new EventEmitter();
  ngOnInit() { }

  goBackToDetails() {
    this.goBack.emit();
  }

  async goToFiles() {
    const sendMessage = await this._translate.get('successfully_send_reclamation').toPromise();
    const text = await this._translate.get('loading_controller_text').toPromise();
    const loading = await this._loading.create({
      message: text,
    });
    await loading.present();
    this._http.post<any>(`${environment.api_url}/insurance/${this.orderId}/reclamation`, this.insuranceDetails).subscribe(async result => {
      loading.dismiss();
      (await this._toast.create({
        message: sendMessage,
        duration: 3000
      })).present();
      this.termsAccepted.emit(result.notification_id);
    })

  }
}
