import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anonymous-offer-creation-failed-dialog',
  templateUrl: './anonymous-offer-creation-failed-dialog.component.html',
  styleUrls: ['./anonymous-offer-creation-failed-dialog.component.scss'],
})
export class AnonymousOfferCreationFailedDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
