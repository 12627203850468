<h2 mat-dialog-title translate>delete_bet_headline</h2>
<mat-dialog-content class="mat-typography">
  <p translate>sure_reoke_bet_headline</p>
  <form class="mt-4" [formGroup]="group">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label translate>storno_state</mat-label>
      <mat-select formControlName="state">
        <mat-option *ngFor="let state of states" [value]="state">{{state | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label translate>reason_for_cancellation</mat-label>
      <mat-select formControlName="reason">
        <mat-option *ngFor="let reason of reasons" [value]="reason">{{reason | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width mt-2" appearance="outline">
      <mat-label translate>reason_for_cancellation</mat-label>
      <textarea matInput formControlName="message" style="min-height: 200px"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">{{'cancel' | translate}}</button>
  <button mat-button [disabled]="group.invalid" (click)="deleteBet()" cdkFocusInitial>OK</button>
</mat-dialog-actions>