<div class="table-responsive">
  <table mat-table [dataSource]="dataSource" class="full-width" matSort multiTemplateDataRows>
    <!-- Master selection -->
    <ng-container matColumnDef="selection">
      <th mat-header-cell *matHeaderCellDef style="min-width: 60px">
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="column.id" *ngFor="let column of columnDefinition; let dataindex = index">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
        [ngStyle]="{'min-width': column.minWidth ? column.minWidth : 'auto'}">
        {{ column.name | translate }} <button mat-icon-button [matMenuTriggerFor]="appMenu" class="filter-button"
          *ngIf="column.data.length > 0" [matMenuTriggerData]="{column}" (click)="$event.stopPropagation()">
          <mat-icon>filter_list</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element" [class.text-center]="column.textCenter">
        <span *ngIf="column.prefix" class="me-1" [innerHTML]="column.prefix"></span>
        <span *ngIf="column.data.length > 0">
          <span *ngIf="column.translate && element[column.id] != null ">{{ (element?.[column.id] ?? '') | translate
            }}</span>
          <span *ngIf="!column.translate && element[column.id] != null && !column.feedback"
            [innerHTML]="element?.[column.id] ?? ''"></span>
        </span>
        <span *ngIf="column.suffix" class="ms-1" [innerHTML]="column.suffix"></span>
        <ion-button shape="circle" fill="none" *ngIf="column.feedback && ((column.checkIfRated && !((element?.[column.id]) === '-') || !isSupplier))"
          (click)="$event.stopPropagation(); column.feedback!(element.rawData)">
          <ion-img class="rating" [src]="(element?.[column.id])" *ngIf="(element?.[column.id]) !== '-'"></ion-img>
          <ion-icon *ngIf="(element?.[column.id]) === '-'" name="star-half-outline"></ion-icon>
        </ion-button>
      </td>

      <mat-menu #appMenu="matMenu" yPosition="above">
        <ng-template matMenuContent let-column="column">
          <div class="menu-parent" (click)="$event.stopPropagation()">
            <ion-button color="primary" class="full-width" (click)="clearFilter(column.id)">
              <ion-icon name="close-circle-outline" class="me-2"></ion-icon>
              <span translate>clear_filter</span>
            </ion-button>

            <hr />
            <mat-form-field class="full-width" appearance="outline">
              <mat-label translate>search</mat-label>
              <input matInput [(ngModel)]="searchValues[column.id]" />
            </mat-form-field>

            <div *ngIf="getValues(column.id).length ==0" translate>no_values </div>

            <mat-selection-list>
              <mat-list-option *ngIf="getValues(column.id).length>0" (click)="$event.stopPropagation()" class="mb-4"
                [selected]="getValues(column.id).length == columnFilter[column.id]?.length"
                (selectedChange)="toggleAllValues(column.id, $event)">
                <span translate>select_all</span>
              </mat-list-option>
              <mat-list-option [matTooltip]="column.feedback ? null : getValueForTooltip(value)" class="mt-2"
                *ngFor="let value of getValues(column.id)" (click)="$event.stopPropagation()"
                [selected]="columnFilter[column.id]?.includes(value)"
                (selectedChange)="toggleFilter(column.id, value, $event)">
                <span class="two-line-ellipsis" [innerHTML]="value ?? '' | translate" *ngIf="!column.feedback"></span>
                <img [src]="value" *ngIf="column.feedback" width="30" />
              </mat-list-option>
            </mat-selection-list>
          </div>
        </ng-template>
      </mat-menu>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.expanded-row]="expandedElement === row"
      (click)="openBottomSheet(row.rawData )"></tr>
  </table>
</div>
<div class="clearfix">
  <div class="float-start">
    <ion-button [disabled]="!this.selection.hasValue()" (click)="downloadFile(this.selection.selected)"
      class="ms-3 mt-2">
      <ion-icon name="download-outline" class="me-2"></ion-icon>
      <span translate>csv_export</span>
    </ion-button>
  </div>

  <mat-paginator [pageIndex]="index" [length]="length" [pageSize]="size" (page)="handlePageEvent($event)"
    [pageSizeOptions]="[5, 10, 25, 100]" class="float-end"></mat-paginator>
</div>

<ng-template #expandedDetailDefault>
  <i>No template has been defined yet</i>
</ng-template>