import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToMuchLdmOrToHeavyModalComponent } from '../crate-input/to-much-ldm-or-to-heavy-modal/to-much-ldm-or-to-heavy-modal.component';

@Component({
  selector: 'app-loading-meter-input',
  templateUrl: './loading-meter-input.component.html',
  styleUrls: ['./loading-meter-input.component.scss'],
})
export class LoadingMeterInputComponent implements OnInit {
  methodCalled = false;
  @Input()
  isIframe: boolean = false;
  @Input()
  group!: FormGroup;

  @Input()
  individual = false;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    this.group.valueChanges.subscribe(result => {
      if (this.individual) return;
      if (result.loading_meter > 13.6 || result.weight > 24000) {
        if (!this.methodCalled) {
          this.methodCalled = true;
          setTimeout(() => {
            this.showDialog();
          }, 1000)
        }
      }
    })
  }

  showDialog() {
    this.methodCalled = false;
    this.dialog.open(ToMuchLdmOrToHeavyModalComponent,
      { width: '400px' });
  }
}
