import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-data-verification-required-modal',
  templateUrl: './data-verification-required-modal.component.html',
  styleUrls: ['./data-verification-required-modal.component.scss'],
})
export class DataVerificationRequiredModalComponent implements OnInit {

  constructor(public _modal: ModalController,
    private _router: Router) { }

  ngOnInit() { }

  goVerification() {
    this._modal.dismiss();
    this._router.navigate(['/dashboard/profile/verification']);
  }

}
