import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-price-negotiate',
  templateUrl: './dashboard-price-negotiate.component.html',
  styleUrls: ['./dashboard-price-negotiate.component.scss'],
})
export class DashboardPriceNegotiateComponent implements OnInit {

  loading = false;
  group: FormGroup = this._builder.group({
    price: [null, [Validators.required, Validators.min(1)]]
  });

  constructor(private dialogRef: MatDialogRef<DashboardPriceNegotiateComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private _builder: FormBuilder, private http: HttpClient) { }

  ngOnInit() { }

  askForNegotiation() {
    this.loading = true;
    let url = `${environment.api_url}/anonymous/${this.data.anonymousData._id}/offer/${this.data.offer._id}/customer/negotiate`;
    if (this.data.isSupplier) {
      url = `${environment.api_url}/anonymous/${this.data.anonymousData._id}/offer/${this.data.offer._id}/logistic/negotiate`;
    }
    this.http.put<any>(url, {
      price: this.group.get('price')?.value
    }).subscribe(result => {
      this.dialogRef.close(true);
    }, error => {
      this.dialogRef.close({
        error: error
      });
    })

  }


}
