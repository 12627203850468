

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-email-form',
    templateUrl: './email-form.component.html',
    styleUrls: ['./email-form.component.scss'],
})
export class EmailFormComponent implements OnInit {

    constructor(private _builder: FormBuilder, private _loading: LoadingController, private _translate: TranslateService,
        private _http: HttpClient, private _toast: ToastController
    ) { }

    ngOnInit() { }

    @Output()
    goBack: EventEmitter<any> = new EventEmitter();


    @Input()
    offer_number: string | undefined;
    @Input()
    kind: any;

    @Output()
    didSend: EventEmitter<string> = new EventEmitter();

    group: FormGroup = this._builder.group({
        message: [null, Validators.required],
    });

    async send() {
        const sendMessage = await this._translate.get('message_sent').toPromise();
        const text = await this._translate.get('loading_controller_text').toPromise();
        const loading = await this._loading.create({
            message: text,
        });
        loading.present();
        this._http.post<any>(`${environment.api_url}/insurance/general`, {
            offer_number: this.offer_number,
            message: this.group.get('message')?.value,
            kind: this.kind
        }).subscribe(async result => {
            loading.dismiss();
            (await this._toast.create({
                message: sendMessage,
                duration: 3000
            })).present();
            this.didSend.emit('mail sent');
        });
    }
    goBackToFirstView() {
        this.goBack.emit();
    }
}
