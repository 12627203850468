import { Inject, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Preferences } from '@capacitor/preferences';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NavController } from '@ionic/angular';
import { DOCUMENT, Location } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import CryptoJS from 'crypto-js';
import { NgxGdprCookieConsentConfig, NgxGdprCookieConsentService } from 'ngx-gdpr-cookie-consent';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, Event, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

declare var Tawk_API: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  showLoader = true;
  scriptChatInit = this._renderer.createElement('script');
  scriptChat = this._renderer.createElement('script');
  isTest = false;
  config: NgxGdprCookieConsentConfig = {
    image: 'assets/logo/logo_vehar.png',
    title: 'Wir lieben Cookies!',
    subtitle: 'Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.',
    individualPrivacySettingsButtonText: 'Individuelle Datenschutzeinstellungen',
    backButtonText: 'Zurück',
    confirmButtonText: 'Akzeptieren',
    confirmAllButtonText: 'Alle akzeptieren',
    legalLinks: [
      {
        name: 'Impressum',
        url: 'https://veharlogistiko.de/pages/allgemeine-geschaftsbedingungen-und-kundeninformationen'
      },
      {
        name: 'Datenschutzerklärung',
        url: 'https://veharlogistiko.de/pages/datenschutzerklarung'
      },
      {
        name: 'AGB',
        url: 'https://veharlogistiko.de/pages/allgemeine-geschaftsbedingungen'
      }
    ]
  };

  constructor(private translate: TranslateService,
    private _auth: AngularFireAuth,
    private _afs: AngularFirestore,
    private _nav: NavController,
    private _location: Location,
    private _cookie: NgxGdprCookieConsentService,
    private _renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,



    @Inject(DOCUMENT) private _document: any) {
    // this.addChatWidget();
    this.setLang();
  }

  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  ngOnInit(): void {
    this.sendConsentDefaultEvent();
    registerLocaleData('de');
    this.isTest = !environment.production;
    this.printDisclaimer();
    this.listenToFirstUrl();
    if (window.location.href.includes('/iframe')) {
      this.showLoader = false;
      return;
    }
    this.updateLastSeenState();
    this._auth.authState.subscribe(user => {
      if (user?.uid) {
        this.getAuthentication();
      } else {
        this.showLoader = false;
        this._nav.navigateRoot('login');
      }
    });
  }

  sendConsentDefaultEvent(): void {
    setTimeout(() => {
      this._cookie.selectionState.subscribe(stater => {
        this.updateConsent(stater);
      });
    }, 800);
  }

  updateConsent(stater: any): void {
    const isExternalGranted = stater.filter((state: any) => state.value).map((state: any) => state.key).includes('external');
    (window as any).dataLayer = (window as any).dataLayer || [];
    function gtag() { (window as any).dataLayer.push(arguments); }

    const currentConsentState = {
      'ad_storage': isExternalGranted ? 'granted' : 'denied',
      'analytics_storage': isExternalGranted ? 'granted' : 'denied',
      'ad_personalization': isExternalGranted ? 'granted' : 'denied',
      'ad_user_data': isExternalGranted ? 'granted' : 'denied',
      'personalization_storage': isExternalGranted ? 'granted' : 'denied',
      'functionality_storage': isExternalGranted ? 'granted' : 'denied',
      'security_storage': isExternalGranted ? 'granted' : 'denied'
    };

    const previousConsentEvent = (window as any).dataLayer.find((event: any) => event.event === 'consent_update');

    if (!previousConsentEvent ||
      previousConsentEvent.ad_storage !== currentConsentState.ad_storage ||
      previousConsentEvent.analytics_storage !== currentConsentState.analytics_storage ||
      previousConsentEvent.ad_personalization !== currentConsentState.ad_personalization ||
      previousConsentEvent.ad_user_data !== currentConsentState.ad_user_data) {

      (window as any)['gtag']('consent', 'update', currentConsentState);
      (window as any).dataLayer.push({
        'event': 'consent_update',
        ...currentConsentState
      });
    }
  }

  listenToFirstUrl() {
    // Listen to the first navigation end event
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.checkForSessionId();
    });
  }

  checkForSessionId() {
    this.route.queryParams.subscribe(async params => {
      if (params['sessionId']) {
        const user = await this._auth.currentUser;
        this._afs.collection('users').doc(user?.uid).update({
          sessionId: params['sessionId']
        })
      }

    });
  }

  updateLastSeenState() {
    setInterval(async () => {
      const user = await this._auth.currentUser;
      if (!user?.uid) return;
      this._afs.collection('users').doc(user.uid).update({
        lastSeen: new Date()
      })
    }, 5000)

  }

  async getAuthentication() {
    // this._http.get<any>(`${environment.api_url}/user`).subscribe(result => {
    //   if (result) {
    //     if (result.personal) {
    //       this.tryTawkLogin(result);
    //     }
    //   }
    //   this.showLoader = false;
    // }, error => {
    //   this.showLoader = false;
    // })
    this.showLoader = false;
    if (this._location.path() == "") {
      this._nav.navigateRoot('dashboard');
    }
  }

  hashInBase64(userId: string) {
    var hash = CryptoJS.HmacSHA256(userId, 'c600155f792fe49dbdec21b3b5891c1c7fe2879a');
    return CryptoJS.enc.Hex.stringify(hash);
  }

  async tryTawkLogout() {
    if (!Tawk_API.logout) {
      setTimeout(() => this.tryTawkLogout(), 500);
      return;
    }
    Tawk_API.logout();
  }

  async tryTawkLogin(profile: any) {
    try {
      const user = await this._auth.currentUser;
      if (!Tawk_API || !user?.uid) {
        setTimeout(() => this.tryTawkLogin(profile), 500);
        return;
      }

      Tawk_API.login({
        name: `${profile.personal.firstname ?? ''} ${profile.personal.lastname ?? ''}`,
        email: user!.email,
        userId: user!.uid,
        hash: this.hashInBase64(user!.uid)
      });
    } catch (error) { }
  }

  async setLang() {
    const lang = (await Preferences.get({ key: 'language' })).value ?? 'de';
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  addChatWidget() {
    this.scriptChatInit.text = `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    Tawk_API.customStyle = {
      zIndex : 100
    };
    (function(){
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/64f07d79b2d3e13950ed2467/1h95mk97d';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
      })();`;
    this._renderer.appendChild(this._document.body, this.scriptChatInit);
  }

  printDisclaimer() {
    const asciiArt = `
  __      ________ _    _          _____  
  \\ \\    / /  ____| |  | |   /\\   |  __ \\ 
   \\ \\  / /| |__  | |__| |  /  \\  | |__) |
    \\ \\/ / |  __| |  __  | / /\\ \\ |  _  / 
     \\  /  | |____| |  | |/ ____ \\| | \\ \\ 
      \\/   |______|_|  |_|_/    \\_\\_|  \\_\\
                                          
                                          
  `;
    console.log("%c" + asciiArt, "font-size: 14px; color: #102045");
    console.log(""),
      console.log("%cSicherheitslücke gefunden? info@sdkindustries.de", "font-size: 16px"),
      console.log(""),
      console.log("%cACHTUNG!", "font-size:20px; color: rgb(215, 75, 75)"),
      console.log("Dieses Browser Feature ist nur für IT-Experten."),
      console.log("Füge keinen Code oder Text hier ein, solange du dich nicht auskennst oder seine Auswirkung nicht kennst."),
      console.log("Du kannst deine Vehar Kontensicherheit gefährden. ");
    console.log("Du suchst nach Entwicklern für deine App oder Website? Melde dich bei uns:  info@sdkindustries.de");
  }
}
