import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-checkout-completion-modal',
  templateUrl: './checkout-completion-modal.component.html',
  styleUrls: ['./checkout-completion-modal.component.scss'],
})

export class CheckoutCompletionModalComponent implements OnInit {
  isLoading = false;
  claimsLoading = true;
  isLoading2 = false;
  isInvoiceAllowed = false;

  constructor(private _http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: any, private nav: NavController,
    private ref: MatDialogRef<CheckoutCompletionModalComponent>) { }

  ngOnInit() {

    this.checkIfInvoiceAllowed();

  }
  checkIfInvoiceAllowed() {
    this._http.get<any>(`${environment.api_url}/user/invoicePayable`).subscribe(result => {
      this.claimsLoading = false;
      this.isInvoiceAllowed = true;
    }, error => {
      this.claimsLoading = false;
    })
  }

  async payWithStripe() {
    this.isLoading2 = true;
    this.data.payment_type = 'stripe_progress';
    let url = `${environment.api_url}/order/direct`;
    if (this.data._id && this.data.offer_number && this.data.product !== 'Vehar® Direct') {
      url = `${environment.api_url}/order/${this.data._id}/pay`

    } else if (this.data._id) {
      url = `${environment.api_url}/order/direct/${this.data._id}`;
    }
    if (this.data.offerId) {
      await this.storeAcceptance(this.data);
    }
    this.sendGTAG();
    setTimeout(() => {
      this._http.post<any>(url, this.data).subscribe(result => {

        window.open(result.url, '_self');
        this.ref.close();
      })
    }, 300)

  }

  async payWithInvoice() {
    this.isLoading = true;
    this.data.payment_type = 'invoice';
    let url = `${environment.api_url}/order/direct`;
    if (this.data._id && this.data.offer_number && this.data.product !== 'Vehar® Direct') {
      url = `${environment.api_url}/order/${this.data._id}/pay`
    } else if (this.data._id) {
      url = `${environment.api_url}/order/direct/${this.data._id}`;
    }
    if (this.data.offerId) {
      await this.storeAcceptance(this.data);
    } 
    this.sendGTAG();
    this._http.post<any>(url, this.data).subscribe(result => {
      this.nav.navigateRoot('/dashboard/order');

      this.ref.close({ data: result.id });
    });
  }
  async payVorab() {
    this.data.payment_type = 'before';

    this.isLoading = true;
    let url = `${environment.api_url}/order/direct`;
    if (this.data._id && this.data.offer_number && this.data.product !== 'Vehar® Direct') {
      url = `${environment.api_url}/order/${this.data._id}/pay`
    } else if (this.data._id) {
      url = `${environment.api_url}/order/direct/${this.data._id}`;
    }
    if (this.data.offerId) {
      await this.storeAcceptance(this.data);
    }

    this.sendGTAG();
    this._http.post<any>(url, this.data).subscribe(result => {
      this.nav.navigateRoot('/dashboard/order');

      this.ref.close({ data: result.id });
    });
  }

  async storeAcceptance(data:any) {
    await this._http.put<any>(`${environment.api_url}/anonymous/${this.data._id}/offer/${this.data.offerId}/customer/accept`, data).toPromise();
  }
  close() {
    this.ref.close();
  }
  sendGTAG(){
    (window as any).gtag('event', 'conversion', {
      'send_to': 'AW-16564255960/9qH_CNbC08UZENj5udo9',
      'transaction_id': `direct-${this.data.offerId}`
    });
  }
}
