<form [formGroup]="loading_kind_form">
  <div class="row w-100 overflow-hidden">
    <div class="col-12 mt-2">
      <ion-button color="secondary" class="m-2 full-width" (click)="goBack()">
        <ion-icon name="arrow-back-outline"></ion-icon>
        <ion-label class="ms-2" translate>go_back_data</ion-label>
      </ion-button>
    </div>

    <div class="col-sm-12 col-md-4 col-xl-4 col-xxl m-2">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label translate>loading_kind</mat-label>
        <mat-select (selectionChange)="loadingKindChanged($event)" formControlName="loading_kind">
          <mat-option *ngFor="let kind of loading_kinds" [value]="kind">{{kind | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-12 col-md-4 col-xl-4 col-xxl m-2">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label translate>unloading_kind</mat-label>
        <mat-select (selectionChange)="unloadingKindChanged($event)" formControlName="unloading_kind">
          <mat-option *ngFor="let kind of unloading_kinds" [value]="kind">{{kind | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-12 col-md-4 col-xl-4 col-xxl m-2">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label translate>car_type</mat-label>
        <mat-select [disabled]="true" formControlName="car">
          <mat-option *ngFor="let car of cars" [value]="car">{{car | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="alert alert-warning m-2" role="alert" *ngIf="showOverSizeAlert">
      <p class="alert-heading" [innerHTML]="'oversize_title' | translate"></p>
      <h4 translate>oversize_message</h4>
    </div>
  </div>
  <hr>
  <div class="row overflow-hidden">
    <div class="col-sm-12 col-md-6 col-xl-4 col-xxl mt-3 mt-xxl-0 mb-3">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            <img src="assets/trucks/sprinter.jpg" height="100"  /><br />
            <span translate><span translate>sprinter_image</span></span>

            <hr />
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list lines="none">
            <ion-item>
              <ion-icon name="resize-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>length</span>:</b> <span class="ms-2">480 cm</span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="code-working-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>wide</span>:</b> <span class="ms-2">230 cm</span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="swap-vertical-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>height</span>:</b> <span class="ms-2">220 cm</span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="barbell-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>capacity</span>:</b> <span class="ms-2">800 kg</span>
              </ion-label>
            </ion-item>
            <ion-item lines="none">

              <ion-label class="ms-2" text-wrap>
                <small><b>{{ 'pickup_from' | translate}}:</b> {{plan3Range.split('-')[0].trim()}}</small>
              </ion-label>
            </ion-item>
            <ion-item lines="none" style="margin-top: -15px !important;">

              <ion-label class="ms-2" text-wrap>
                <small> <b>{{ 'deliver_from_text' | translate}}:
                  </b>{{plan3Range}}</small>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>

        <div class="card-footer">
          <div class="m-3">
            <hr />

            <h3 class="price" translate>price</h3>
            <h3 class="price">{{ vehiclePriceData[0] ? vehiclePriceData[0]+ ' &euro;' : '-' }}</h3>
            <small translate>additional_tax</small>
          </div>

          <ion-button color="secondary" style="margin: 0px" [disabled]="loading_kind_form.invalid || !firstPossible"
            class="full-width" (click)="selectVehicle(0)" translate>choose</ion-button>
        </div>
      </ion-card>
    </div>
    <div class="col-sm-12 col-md-6 col-xl-4 col-xxl mt-3 mt-xxl-0 mb-3">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            <img src="assets/trucks/truck_75.jpg" height="100"  /><br />
            <span translate><span translate>truck_75</span></span>

            <hr />
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list lines="none">
            <ion-item>
              <ion-icon name="resize-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>length</span>:</b> <span class="ms-2">620 cm</span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="code-working-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>wide</span>:</b> <span class="ms-2">240 cm</span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="swap-vertical-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>height</span>:</b> <span class="ms-2">240 cm</span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="barbell-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>capacity</span>:</b> <span class="ms-2">3.000 kg</span>
              </ion-label>
            </ion-item>
            <ion-item lines="none">

              <ion-label class="ms-2" text-wrap>
                <small><b>{{ 'pickup_from' | translate}}:</b> {{plan3Range.split('-')[0].trim()}}</small>
              </ion-label>
            </ion-item>
            <ion-item lines="none" style="margin-top: -15px !important;">

              <ion-label class="ms-2" text-wrap>
                <small> <b>{{ 'deliver_from_text' | translate}}:
                  </b>{{plan3Range}}</small>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
        <div class="card-footer">
          <div class="m-3">
            <hr />
            <h3 class="price" translate>price</h3>
            <h3 class="price">{{ vehiclePriceData[1] ? vehiclePriceData[1] + ' &euro;' : '-' }}</h3>
            <small translate>additional_tax</small>
          </div>

          <ion-button color="secondary" style="margin: 0px" [disabled]="loading_kind_form.invalid || !seccondPossible"
            class="full-width" (click)="selectVehicle(1)" translate>choose</ion-button>
        </div>
      </ion-card>
    </div>
    <div class="col-sm-12 col-md-6 col-xl-4 col-xxl mt-3 mt-xxl-0 mb-3">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            <img src="assets/trucks/truck_12.jpg" height="100"  /><br />
            <span translate><span translate>truck_12</span></span>

            <hr />
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list lines="none">
            <ion-item>
              <ion-icon name="resize-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>length</span>:</b> <span class="ms-2">720 cm</span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="code-working-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>wide</span>:</b> <span class="ms-2">240 cm</span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="swap-vertical-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>height</span>:</b> <span class="ms-2">250 cm</span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="barbell-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>capacity</span>:</b> <span class="ms-2">5.500 kg</span>
              </ion-label>
            </ion-item>

            <ion-item lines="none">

              <ion-label class="ms-2" text-wrap>
                <small><b>{{ 'pickup_from' | translate}}:</b> {{plan3Range.split('-')[0].trim()}}</small>
              </ion-label>
            </ion-item>
            <ion-item lines="none" style="margin-top: -15px !important;">

              <ion-label class="ms-2" text-wrap>
                <small> <b>{{ 'deliver_from_text' | translate}}:
                  </b>{{plan3Range}}</small>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>

        <div class="card-footer">
          <div class="m-3">
            <hr />

            <h3 class="price" translate>price</h3>
            <h3 class="price">{{ vehiclePriceData[2] ? vehiclePriceData[2]+ ' &euro;' : '-' }}</h3>
            <small translate>additional_tax</small>
          </div>

          <ion-button color="secondary" style="margin: 0px" [disabled]="loading_kind_form.invalid || !thirdPossible"
            class="full-width" (click)="selectVehicle(2)" translate>choose</ion-button>
        </div>
      </ion-card>
    </div>
    <div class="col-sm-12 col-md-6 col-xl-4 col-xxl mt-3 mt-xxl-0 mb-3">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            <img src="assets/trucks/sattel.jpg" height="100"  /><br />
            <span translate><span translate>big_truck</span></span>

            <hr />
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list lines="none">
            <ion-item>
              <ion-icon name="resize-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>length</span>:</b> <span class="ms-2">1360 cm</span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="code-working-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>wide</span>:</b> <span class="ms-2">240 cm</span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="swap-vertical-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>height</span>:</b> <span class="ms-2">260 cm</span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-icon name="barbell-outline"></ion-icon>
              <ion-label class="ms-2">
                <b><span translate>capacity</span>:</b> <span class="ms-2">24.000 kg</span>
              </ion-label>
            </ion-item>
            <ion-item lines="none">

              <ion-label class="ms-2" text-wrap>
                <small><b>{{ 'pickup_from' | translate}}:</b> {{plan3Range.split('-')[0].trim()}}</small>
              </ion-label>
            </ion-item>
            <ion-item lines="none" style="margin-top: -15px !important;">

              <ion-label class="ms-2" text-wrap>
                <small> <b>{{ 'deliver_from_text' | translate}}:
                  </b>{{plan3Range}}</small>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>

        <div class="card-footer">
          <div class="m-3">
            <hr />

            <h3 class="price" translate>price</h3>
            <h3 class="price">{{ vehiclePriceData[3] ? vehiclePriceData[3]+ ' &euro;' : '-'
              }}</h3>
            <small translate>additional_tax</small>
          </div>

          <ion-button color="secondary" style="margin: 0px" [disabled]="!loading_kind_form.valid || !fourthPossible "
            class="full-width" (click)="selectVehicle(3)" translate>choose</ion-button>
        </div>
      </ion-card>
    </div>
  </div>
</form>