<div class="row mt-4 mb-4">
  <div class="col-lg-2 offset-lg-1  text-center border-right">
    <div class="full-width">
      <img src="assets/trucks/planekoffer.png" style="mix-blend-mode: multiply;" height="100" width="150" />
    </div>
    <b><span [style.color]="isIframe? 'white': ''"  translate>sprinter_image</span></b>
  </div>
  <div class="col-lg-3 mt-4 mt-md-0 text-center border-right">
    <div class="full-width">
      <img [src]="'assets/trucks/75freigestellt.png' "  height="100" width="150"/>
    </div>
    <b   ><span [style.color]="isIframe? 'white': ''"   translate>truck_75</span></b>

  </div>
  <div class="col-lg-3 text-center border-right mt-4 mt-md-0">
    <div class="full-width">
      <img src="assets/trucks/12freigestellt.png" height="100" width="150" />
    </div>
    <b><span [style.color]="isIframe? 'white': ''"  translate>truck_12</span></b>
  </div>
  <div class="col-lg-3 text-center border-right mt-4 mt-md-0">
    <div class="full-width">
      <img src="assets/trucks/planefrei.png" height="100" width="150" />
    </div>
    <b><span [style.color]="isIframe? 'white': ''" translate>big_truck</span></b>
  </div>
</div>