import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import dayjs from 'dayjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent {
  @ViewChild('salesChart')
  private revenueChartRef: ElementRef | undefined;
  private revenueChart?: Chart;

  constructor(private _translate: TranslateService,
    private _loading: LoadingController,
    private _http: HttpClient) { }

  @Input()
  private path!: string;

  criteria = 'total_sales';
  timeframe = 2;
  sum = 'LDM';

  criteriaData: any[] = [];
  sumData: any[] = [];

  updateView(statusData: number[]) {
    this.revenueChart!.data.datasets[0].data = statusData
    this.revenueChart!.update();
  }
  ngAfterViewInit(): void {
    this.initLine();
  }

  async loadData() {
    const result = await this._http.get<any>(`${environment.api_url}${this.path}?criteria=${this.criteria}&sum=${this.sum}`).toPromise();
    const data = await this.generateData(result.criteria, result.sum);
    this.criteriaData = result.criteria;
    this.sumData = result.sum;
    this.revenueChart!.data.labels = data.labels;
    this.revenueChart!.data.datasets[0].data = data.criteria;
    this.revenueChart!.data.datasets[0].label = await this._translate.get(this.criteria).toPromise();
    this.revenueChart!.data.datasets[1].data = data.sum;
    this.revenueChart!.data.datasets[1].label = await this._translate.get(this.sum).toPromise();
    this.revenueChart!.update();
  }

  async generateData(data: any[], data2: any[]) {
    const labels: Promise<string>[] = [];
    const criteria: number[] = [];
    const sum: number[] = [];
    const startDate = dayjs().subtract(this.timeframe, "month").startOf("month");
    for (var i = 0; i < (this.timeframe + 1); i++) {
      const dt = startDate.add(i, 'month');
      labels.push(this._translate.get(dt.format('MMMM').toLowerCase()).toPromise());
      criteria.push(data.find(elem => elem._id.month == dt.month() + 1)?.sum ?? 0);
      sum.push(data2.find(elem => elem._id.month == dt.month() + 1)?.sum ?? 0);
    }
    return {
      labels: await Promise.all(labels),
      criteria,
      sum
    };
  }

  initLine() {
    setTimeout(async () => {
      const data = {
        labels: [],
        datasets: [
          {
            label: 'Dataset 1',
            data: [],
            yAxisID: 'y-axis-1',
            backgroundColor: '#8D8D8D'
          },
          {
            label: 'Dataset 1',
            data: [],
            yAxisID: 'y-axis-2',
            backgroundColor: '#102045',
          },
        ]
      };

      this.revenueChart = new Chart(this.revenueChartRef!.nativeElement, {
        type: 'bar',
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false

            },
            title: {
              display: false,
            }
          },
          scales: {
            'y-axis-1': {
              type: 'linear',
              display: true,
              position: 'left'
            },
            'y-axis-2': {
              type: 'linear',
              display: true,
              position: 'right',
              grid: {
                drawOnChartArea: false
              }
            }
          }
        },
      });
      this.loadData();
    }, 100)
  }

  async exportData() {
    const loader = await this._loading.create({
      message: 'Exporting data ...'
    });
    await loader.present();

    const header = [
      await this._translate.get('month').toPromise(), 
      await this._translate.get(this.criteria).toPromise(),
      await this._translate.get(this.sum).toPromise()
    ];

    const startDate = dayjs().subtract(this.timeframe, "month").startOf("month");
    const csv: string[] = [];
    for (var i = 0; i < (this.timeframe + 1); i++) {
      const dt = startDate.add(i, 'month'),
        month = await this._translate.get(dt.format('MMMM').toLowerCase()).toPromise(),
        criteria = this.criteriaData.find(elem => elem._id.month == dt.month() + 1)?.sum ?? 0,
        sum = this.sumData.find(elem => elem._id.month == dt.month() + 1)?.sum ?? 0;
      csv.push(`${month},${criteria},${sum}`);
    }

    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    await loader.dismiss();

    a.href = url;
    a.download = 'Data Export.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }


  updateTimeframe(timeframe: number) {
    this.timeframe = timeframe;
    this.loadData();
  }

  updateCriteria(criteria: string) {
    this.criteria = criteria;
    this.loadData();
  }
  changeSum(sum: string) {
    this.sum = sum;
    this.loadData();
  }
}
