import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { City } from 'src/app/types/address';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  countryCodes: any = {
    "Afghanistan": "AF",
    "Albania": "AL",
    "Algeria": "DZ",
    "Andorra": "AD",
    "Angola": "AO",
    // Add more country codes here...
  };

  getCountryCode(countryName: string) {
    const code = this.countryCodes[countryName];
    if (code) {
      return code;
    } else {
      return '';
    }
  }
  constructor(private _auth: AngularFireAuth,
    private _afs: AngularFirestore,
    private _http: HttpClient) { }

  public async findResults(search: string): Promise<City[]> {
    if (!search)
      return [];
    const result = await this._http.get<City[]>(`${environment.api_url}/geo/city/autocomplete?query=${search}`).toPromise();
    return result;
  }

  public async convertPlaceId(place_id: string): Promise<City | undefined> {
    const geocoder = new google.maps.Geocoder();

    const result = await geocoder.geocode({
      placeId: place_id
    });
    if (result.results.length >= 1) {
      const city = {
        lat: result.results[0].geometry.location.lat(),
        lng: result.results[0].geometry.location.lng(),
        zipcode: this.extractFromAdress(result.results[0].address_components, "postal_code"),
        city: this.extractFromAdress(result.results[0].address_components, "locality"),
        country: this.extractFromAdress(result.results[0].address_components, "country"),
        country_code: this.extractFromAdress(result.results[0].address_components, "country_code"),
      } as City;
      return city;
    }
    return undefined;
  }

  public extractFromAdress(components: any, type: any) {
    for (var i = 0; i < components.length; i++)
      for (var j = 0; j < components[i].types.length; j++)
        if (components[i].types[j] == type.replace('country_code', 'country')) {
          if (type == 'country_code') {
            return components[i].short_name
          } return components[i].long_name;
        }
    return "";
  }

  public async loadProfile() {
    const uid = (await this._auth.currentUser)?.uid;
    if (!uid) return;

    return (await this._afs.collection('users').doc<any>(uid).get().toPromise()).data();
  }

  public async loadCityFromProfile() {
    const user = await this.loadProfile();

    return {
      zipcode: user.address?.zipcode,
      city: user.address?.city,
      country_code: user.address?.country ?? 'DE',
      street: user.address?.street
    } as City;
  }
}
