<div class="main-container">
  <mat-tab-group (selectedTabChange)="renderMap($event)" *ngIf="data">
    <mat-tab *ngFor="let shipment of data.groups; let i = index">
      <ng-template mat-tab-label>
        {{i+1}}. <span translate>load_loadoff</span>
      </ng-template>


      <div class="row mt-2">
        <div class="col-6">
          <ion-card>
            <ion-card-content>
              <div class="heading" translate>request_type</div>
              <div class="content">{{ getType(shipment) | translate }}</div>
            </ion-card-content>
          </ion-card>

        </div>
        <div class="col-6">
          <ion-card>
            <ion-card-content>
              <div class="heading" translate>distance</div>
              <div class="content">{{shipment.distance }} Km</div>
            </ion-card-content>
          </ion-card>
        </div>
        <div class="col-12">
          <ion-card>
            <ion-card-content>
              <cookie-container cookieId="external" (didLoaded)="loadMap(0)">
                <div class="map" [id]="'map-'+i"></div>
              </cookie-container>
            </ion-card-content>
          </ion-card>
        </div>
        <div class="col-12 col-lg-6 mt-1">
          <ion-card>
            <ion-card-header>
              <ion-card-title translate>pickup</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <p>{{ shipment.pickup?.companyname }}</p>
              <p>{{ shipment.pickup.contact }}</p>
              <p>{{ shipment.pickup.name }}</p>
              <p>{{ shipment.pickup.street }} {{
                shipment.pickup.housenumber }}</p>
              <p>{{ shipment.pickup.zipcode }}
                {{ shipment.pickup.city }} -
                {{ shipment.pickup.country.toUpperCase() }}
                <img class="ms-2 mb-1" src="assets/countries/{{ (shipment.pickup.country ?? '').toLowerCase() }}.svg"
                  width="20" />
              </p>
              <p *ngIf="shipment.additional_information.pickup_start_date">

                <b translate>pickup_date</b>: <span>{{ shipment.additional_information.pickup_start_date | date:
                  'dd.MM.yyyy'}} - {{
                  shipment.additional_information.pickup_end_date | date:
                  'dd.MM.yyyy'}} {{ shipment.additional_information.pickup_start_time }} - {{
                  shipment.additional_information.pickup_end_time }} </span>
              </p>
              <p *ngIf="shipment.loading_kind">
                <b translate>loading_kind</b>: <span>{{shipment.loading_kind | translate }} </span>
              </p>
              <p *ngIf="shipment.pickup.reference">
                <b translate>pickup_reference</b>: <span>{{ shipment.pickup.reference}}</span>
              </p>
              <p *ngIf="shipment.pickup.email">
                <b translate>email</b>: <a href="mailto:{{shipment.pickup.email}}">{{
                  shipment.pickup.email
                  }}</a>
              </p>
              <p *ngIf="shipment.pickup.phone">
                <b translate>phone</b>: <span>{{ shipment.pickup.phone}}</span>
              </p>
              <p *ngIf="shipment.pickup.notes">
                <b translate>additional_notes</b>: <br><span>{{ shipment.pickup.notes }}</span>
              </p>
            </ion-card-content>
          </ion-card>
        </div>
        <div class="col-12 col-lg-6 mt-1">
          <ion-card>
            <ion-card-header>
              <ion-card-title translate>delivery</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <p>{{ shipment.deliver?.companyname }}</p>
              <p>{{ shipment.deliver.contact }}</p>
              <p>{{ shipment.deliver.name }}</p>
              <p>{{ shipment.deliver.street }} {{
                shipment.deliver.housenumber }}</p>
              <p>{{ shipment.deliver.zipcode }}
                {{ shipment.deliver.city }} -
                {{ shipment.deliver.country.toUpperCase() }}
                <img class="ms-2 mb-1" src="assets/countries/{{ (shipment.deliver.country ?? '').toLowerCase() }}.svg"
                  width="20" />
              </p>
              <p *ngIf="shipment.additional_information.deliver_start_date">
                <b translate>delivery_date</b>: <span>{{ shipment.additional_information.deliver_start_date | date:
                  'dd.MM.yyyy'}} - {{
                  shipment.additional_information.deliver_end_date | date:
                  'dd.MM.yyyy'}} - {{ shipment.additional_information.deliver_start_time }} {{
                  shipment.additional_information.deliver_end_time }} </span>
              </p>
              <p *ngIf="shipment.loading_kind">
                <b translate>unloading_kind</b>: <span>{{shipment.unloading_kind | translate }} </span>
              </p>
              <p *ngIf="shipment.deliver.reference">
                <b translate>deliver_reference</b>: <span>{{ shipment.deliver.reference}}</span>
              </p>
              <p *ngIf="shipment.deliver.email">
                <b translate>email</b>: <a href="mailto:{{shipment.deliver.email}}">{{
                  shipment.deliver.email }}</a>
              </p>
              <p *ngIf="shipment.deliver.phone">
                <b translate>phone</b>: <span>{{ shipment.deliver.phone}}</span>
              </p>
              <p *ngIf="shipment.deliver.notes">
                <b translate>additional_notes</b>: <br><span>{{ shipment.deliver.notes }}</span>
              </p>
            </ion-card-content>
          </ion-card>
        </div>
        <div class="col-12 mt-2" *ngIf="data?.bets">
          <ion-card>
            <ion-card-header>
              <ion-card-title translate>
                available_bets
              </ion-card-title>
              <ion-card-subtitle>
                {{this.timer}}
              </ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <div *ngIf="data?.maximumbet">
                <span>{{"maximum_bid" | translate}} &nbsp;</span>
                <span>{{getMaximumBet()}} &euro;</span>
              </div>
              <div class="mt-2">
                <div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" translate>date</th>
                        <th scope="col" translate>price_net</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let offer of data.bets">

                        <td>{{ offer.date | date: 'dd.MM.yyyy HH:mm' }}</td>
                        <td>{{(offer.price).toFixed(2).toLocaleString()}} &euro;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ion-card-content>
          </ion-card>
        </div>
        <div class="col-12" *ngIf="data.status == 'status_anonymous_accepted'">
          <ion-card [style.background]="'#42a94857'">
            <ion-card-header>
              <ion-card-title translate>
                <div translate>status_anonymous_accepted</div>
              </ion-card-title>
              <ion-card-subtitle>
                <div translate>supplier_will_be_informed
                </div>
              </ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <div class="container">
                <div class="row">

                  <div class="col-12 pt-2 pb-2" align="left">

                    <div class="items" align="right">
                      <div class="row" style="max-width: 400px;">
                        <div class="col-6" translate>
                          total_net
                        </div>
                        <div class="col-6" align="right">
                          {{(data.price).toFixed(2)}} &euro;
                        </div>
                      </div>
                    </div>

                    <div class="items" align="right">
                      <div class="row" style="max-width: 400px;">
                        <div class="col-6" translate>
                          totalAdditionalCosts
                        </div>
                        <div class="col-6" align="right">
                          {{(data.totalAdditionalCosts).toFixed(2)}} &euro;
                        </div>
                      </div>
                    </div>
                    <hr>

                    <div class="my-accepted-offer" align="right">
                      <div class="row" style="max-width: 400px;">
                        <div class="col-6" translate>
                          netto_price
                        </div>
                        <div class="col-6" align="right">
                          {{(data.price +data.totalAdditionalCosts).toFixed(2)}} &euro;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ion-card-content>
          </ion-card>
        </div>
        <div class="col-12 mt-2"
          *ngIf="data.offers &&  data.status !== 'status_anonymous_expired' && data.status !== 'status_anonymous_accepted'">
          <ion-card>
            <ion-card-header>
              <ion-card-title translate>
                amount_offers
              </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <div align="center">
                <div *ngIf="data.offers.length == 0" translate>anonymous_no_offers</div>
              </div>
              <div class="mt-2" *ngIf="data.offers.length>0">
                <table mat-table [dataSource]="data.offers" class="mat-elevation-z8">
                  <ng-container matColumnDef="selection">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 60px">
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef translate> price_net</th>
                    <td mat-cell *matCellDef="let element"> {{(element.price).toFixed(2).replace('.',',')}} &euro;
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="oldprice">
                    <th mat-header-cell *matHeaderCellDef translate> old_price</th>
                    <td mat-cell *matCellDef="let element">
                      <div *ngIf="element.oldPrice">
                        {{(element?.oldPrice).toFixed(2).replace('.',',')}} &euro;
                      </div>
                      <div *ngIf="!element.oldPrice">
                        -
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef translate> date</th>
                    <td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'dd.MM.yyyy'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef translate> status</th>
                    <td mat-cell *matCellDef="let element">
                      <div
                        *ngIf="element.status && element.negotiatedByCustomer && element.status == 'ask_for_negotiation'">
                        {{element.status | translate}}</div>
                      <div
                        *ngIf="element.status && !element.negotiatedByCustomer && element.status == 'ask_for_negotiation'">
                        {{'new_offer_by_supplier' | translate}}</div>
                      <div *ngIf="element.status && element.status !== 'ask_for_negotiation'">
                        {{element.status | translate}}
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
              <div class="clearfix mt-4 ms-2 me-2 pt-3" *ngIf="data.offers.length>0">
                <div class=" full-width">
                  <div class="float-end">
                    <ion-button
                      [disabled]="this.selection.selected.length != 1 || selection.selected[0].status == 'negotiation_finally_declined_by_logistic' ||  this.selection.selected[0].status == 'offer_revoked'"
                      color="success" (click)="acceptOffer()">
                      <ion-icon name="checkmark-circle-outline" class="me-2"></ion-icon>
                      <span translate>accept_offer</span>
                    </ion-button>
                    <ion-button color="warning"
                      [disabled]="this.selection.selected.length != 1 || this.selection.selected[0].negotiatedByCustomer || this.selection.selected[0].count >= 3 || selection.selected[0].status == 'negotiation_finally_declined_by_logistic'"
                      (click)=negotiatePrice()>

                      <ion-icon name="hand-left-outline" class="me-2"></ion-icon>
                      <span translate>price_renegotiate</span>
                    </ion-button>
                    <ion-button color="danger"
                      [disabled]="this.selection.selected.length == 0 || selection.selected[0].status == 'negotiation_finally_declined_by_logistic' ||  this.selection.selected[0].status == 'offer_revoked'"
                      (click)="denyOffers()">
                      <ion-icon name="checkmark-circle-outline" class="me-2"></ion-icon>
                      <span translate>deny_offer</span>
                    </ion-button>
                  </div>
                </div>
              </div>
            </ion-card-content>
          </ion-card>
        </div>
        <div class="col-12 mt-2">
          <ion-card>
            <ion-card-header>
              <ion-card-title translate>
                packaging
              </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <div class="mt-2">
                <div *ngIf="shipment.selectedTab == 0">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" translate>quantity</th>
                        <th scope="col" translate>packaging</th>
                        <th scope="col" translate>dimension</th>
                        <th scope="col" translate>weight</th>
                        <th scope="col" translate>stackable_loadable</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let crate of shipment.crates.crates">
                        <th scope="row">{{ crate?.quantity }}</th>
                        <td>{{ crate?.packaging | translate }}</td>
                        <td>({{ crate?.length }}x{{ crate?.wide }}x{{
                          crate?.height }}cm)</td>
                        <td>{{ crate?.weight }} kg</td>
                        <td *ngIf="crate?.stackable" translate>yes</td>
                        <td *ngIf="!crate?.stackable" translate>no</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                <div *ngIf="shipment.selectedTab == 1">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th translate>loading_meter</th>
                        <td>{{ shipment.loading_meter.loading_meter.toLocaleString() }} ldm</td>
                      </tr>
                      <tr>
                        <th translate>total_weight</th>
                        <td> {{ shipment.loading_meter.weight.toLocaleString() }} kg</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div *ngIf="shipment.selectedTab == 2">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th translate>request_type</th>
                        <td translate>
                          request_based_vehicle_type
                        </td>
                      </tr>
                      <tr>
                        <th translate>vehicle_type</th>
                        <td> <span *ngIf="shipment.vehicles.vehicleType == 0" translate>sprinter_image</span>
                          <span *ngIf="shipment.vehicles.vehicleType == 1" translate>truck_75</span>
                          <span *ngIf="shipment.vehicles.vehicleType == 2" translate>truck_12</span>
                          <span *ngIf="shipment.vehicles.vehicleType == 3" translate>big_truck</span>
                          <span *ngIf="shipment.vehicles.vehicleType == 4" translate>big_truck_mega</span>
                          <span *ngIf="shipment.vehicles.vehicleType == 5" translate>truck_thermo</span>
                          <span *ngIf="shipment.vehicles.vehicleType == 6" translate>truck_crate</span>
                          <span *ngIf="shipment.vehicles.vehicleType == 7" translate>truck_coil</span>
                          <span *ngIf="shipment.vehicles.vehicleType == 8" translate>truck_glider</span>
                          <span *ngIf="shipment.vehicles.vehicleType == 9" translate>open_truck</span>
                          <span *ngIf="shipment.vehicles.vehicleType == 10" translate>low_level_truck</span>
                        </td>
                      </tr>
                      <tr>
                        <th translate>total_weight</th>
                        <td>
                          {{ shipment.vehicles.weight }} kg
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ion-card-content>
          </ion-card>
        </div>
        <div class="col-12 mt-2">
          <ion-card>
            <ion-card-header>
              <ion-card-title translate>
                additional_options
              </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <p class="mt-2">
                <b translate>what_is_shipped</b>: <span>{{ shipment.additional_information.what_send |
                  translate }}</span>
              </p>

              <p class="mt-2" *ngIf="shipment.additional_information.additional_notes">
                <b translate>additional_notes_anonymous</b>: <br><span>{{
                  shipment.additional_information.additional_notes }}</span>
              </p>
              <table class="table">
                <tbody>
                  <tr>
                    <th translate>pickup_notification</th>
                    <td>
                      <span>{{includesOption(shipment.additional_information.additional_options, 'pickup_notification')
                        ? 'erforderlich' : 'nicht erforderlich' }}</span>
                    </td>
                    <td
                      *ngIf="includesOption(shipment.additional_information.additional_options, 'transportinsurance')">
                    </td>
                  </tr>
                  <tr>
                    <th translate>delivery_notification</th>
                    <td>
                      <span>{{includesOption(shipment.additional_information.additional_options,
                        'delivery_notification')
                        ? 'erforderlich' : 'nicht erforderlich' }}</span>
                    </td>
                    <td
                      *ngIf="includesOption(shipment.additional_information.additional_options, 'transportinsurance')">
                    </td>
                  </tr>

                  <tr>
                    <th translate>time_frame_booking</th>
                    <td>
                      <span>{{includesOption(shipment.additional_information.additional_options, 'timeframe')
                        ? 'erforderlich' : 'nicht erforderlich' }}</span>
                    </td>
                    <td
                      *ngIf="includesOption(shipment.additional_information.additional_options, 'transportinsurance')">
                    </td>
                  </tr>
                  <tr>
                    <th translate>transportinsurance</th>
                    <td>
                      <span>{{includesOption(shipment.additional_information.additional_options, 'transportinsurance')
                        ? 'erforderlich' : 'nicht erforderlich' }}</span>
                    </td>
                    <td
                      *ngIf="includesOption(shipment.additional_information.additional_options, 'transportinsurance')">
                      <span *ngIf="!insuranceLoading"> <a class="anchor" (click)="downloadInsuranceDocs(getValueForAdditionalInformation(shipment.additional_information.additional_options,
                      'transportinsurance'))"
                          *ngIf="includesOption(shipment.additional_information.additional_options, 'transportinsurance')"
                          translate>download_insurance_docs</a></span>
                      <span *ngIf="insuranceLoading"> <ion-spinner color="secondary" slot="end" name="crescent"
                          class="ms-2"></ion-spinner>
                      </span>

                    </td>
                  </tr>
                  <tr>
                    <th translate>dangerousgoods</th>
                    <td>
                      <span>{{includesOption(shipment.additional_information.additional_options, 'dangerousgoods')
                        ? 'vorhanden' : 'kein Gefahrgut' }}</span>
                      <ul class="ms-2"
                        *ngIf="includesOption(shipment.additional_information.additional_options, 'dangerousgoods')">
                        <li>
                          <span><span translate>dangerous_goods_class</span>: {{
                            getValueForAdditionalInformation(shipment.additional_information.additional_options,
                            'dangerousgoods').dangerous_goods_class
                            }}</span>
                        </li>
                        <li>
                          <span><span translate>packaging_group</span>: {{
                            getValueForAdditionalInformation(shipment.additional_information.additional_options,
                            'dangerousgoods').packaging_group

                            }}</span>
                        </li>
                        <li>
                          <span><span translate>packaging_group</span>: {{
                            getValueForAdditionalInformation(shipment.additional_information.additional_options,
                            'dangerousgoods').un_number
                            }}</span>
                        </li>
                      </ul>
                    </td>
                    <td
                      *ngIf="includesOption(shipment.additional_information.additional_options, 'transportinsurance')">
                    </td>
                  </tr>
                  <tr>
                    <th translate>pallet_exchange</th>
                    <td>
                      <span>{{includesOption(shipment.additional_information.additional_options, 'pallet_exchange')
                        ? 'erforderlich' : 'nicht erforderlich' }}</span>
                      <ul class="ms-2"
                        *ngIf="includesOption(shipment.additional_information.additional_options, 'pallet_exchange')">
                        <li>
                          <span><span translate>quantity</span>: {{
                            getValueForAdditionalInformation(shipment.additional_information.additional_options,
                            'pallet_exchange').qty

                            }}</span>
                        </li>
                      </ul>
                    </td>
                    <td
                      *ngIf="includesOption(shipment.additional_information.additional_options, 'transportinsurance')">
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row" *ngIf="data.totalAdditionalCosts">
                <div class="col" translate>
                  <b translate>total_additonal</b>
                </div>
                <div class="col" align="right"><b>{{data.totalAdditionalCosts.toFixed(2)}} &euro;</b></div>
              </div>

            </ion-card-content>

          </ion-card>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>