import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bet-delete-dialog',
  templateUrl: './bet-delete-dialog.component.html',
  styleUrls: ['./bet-delete-dialog.component.scss'],
})
export class BetDeleteDialogComponent implements OnInit {
  states = ["status_auction_cancelled_free", "status_auction_cancelled_paid"]
  reasons = ["changes_in_disposition", "no_rest_times", "no_delivery_date", "any_other_reason"]
  constructor(private dialogRef: MatDialogRef<BetDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient, private _builder: FormBuilder) { }

  ngOnInit() {
    
    
    const now = dayjs();
    const dueDate = dayjs(this.data.offer_duration);
    if (dueDate.diff(now, "hour") <= 48) {
      this.group.controls['state'].setValue("status_auction_cancelled_paid");
    } else {
      this.group.controls['state'].setValue("status_auction_cancelled_free");
    }
  }

  deleteBet() {
    this.http.delete<any>(`${environment.api_url}/supplier/auction/${this.data._id}/bet?reason=${this.group.get('reason')!.value}&message=${this.group.get('message')!.value}`,).subscribe(async result => {
      this.dialogRef.close({
        result: result
      });
    })
  }

  group: FormGroup = this._builder.group({
    reason: [null, [Validators.required]],
    message: [null, []],
    state: [{ disabled: true, value: 'status_auction_cancelled_free' }, [Validators.required]]
  });
}
