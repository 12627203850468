import { Component, Inject, OnInit, } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ComplaintsModalComponent } from './complaints-modal/complaints-modal.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CheckoutCompletionModalComponent } from '../checkout-completion-modal/checkout-completion-modal.component';

@Component({
  selector: 'app-table-detail-bottomsheet',
  templateUrl: './table-detail-bottomsheet.component.html',
  styleUrls: ['./table-detail-bottomsheet.component.scss'],
})
export class TableDetailBottomsheetComponent implements OnInit {



  mail: string | undefined;
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<TableDetailBottomsheetComponent>,
    private router: Router, private _modal: ModalController, private _translate: TranslateService,
    private dialog: MatDialog,
    private _loading: LoadingController, private _http: HttpClient) { }

  ngOnInit() {

    this.getMail();
  }

  close() {
    this.bottomSheetRef.dismiss();
  }

  async openComplaints() {
    this.bottomSheetRef.dismiss();

    const modal = await this._modal.create({
      component: ComplaintsModalComponent,
      cssClass: 'max-width',
      
      componentProps: { order: this.data }
    });
    await modal.present();
  }

  editOffer() {
    if (this.data.kind == 'Vehar® Anonymous') {
      this.router.navigate([`/dashboard/order/anonymous/${this.data._id}`]);
    } else if (this.data.kind == 'Vehar® Direct') {
      this.router.navigate([`/dashboard/order/direct/${this.data._id}`]);
    } else {
      this.router.navigate([`/dashboard/order/auction/${this.data._id}`]);
    }
  }

  async reloadAnonymousOrder() {
    const text = await this._translate.get('loading_controller_text').toPromise();
    const loading = await this._loading.create({
      message: text,
    });
    loading.present();
    setTimeout(() => {
      this._http.get<any>(`${environment.api_url}/anonymous/${this.data._id}`).subscribe(result => {
        this.data = result;
        this.data.price = this.data.price
        loading.dismiss();
      })
    }, 300)
  }

  getMail() {
    this._http.get<any>(`${environment.api_url}/user`).subscribe(result => {
      if (result?.favourite) {
        if (result.favourite.mail && result.favourite.mail !== 'no_pref') {
          this.mail = result.favourite.mail;
        } else {
          this.mail = 'info@vehar.de';
        }
      } else {
        this.mail = 'info@vehar.de';
      }
    })
  }
  getOfferNr() {
    if (this.data.task_nr) {
      return `Auftrags-Nr: ${this.data.task_nr} | ${this.data.product}`

    } else {
      return `Angebots-Nr: ${this.data.offer_number} | ${this.data.product}`
    }
  }
  payNow() {
    const dialog = this.dialog.open(
      CheckoutCompletionModalComponent,
      {
        data: this.data
      }
    )
    dialog.afterClosed().subscribe(async (result: any) => {
      if (result.data) {
        if (this.data.convertedToDirect) {
          this.reloadDirectOrder();
        } else if(this.data.offer_number.startsWith('auction') && !this.data.convertedToDirect) {
          this.reloadAuctionOrder();
        } else {
          this.reloadAnonymousOrder();
        }
      }
    })
  }
  async reloadDirectOrder() {
    const text = await this._translate.get('loading_controller_text').toPromise();
    const loading = await this._loading.create({
      message: text,
    });
    loading.present();
    setTimeout(() => {
      this._http.get<any>(`${environment.api_url}/direct/${this.data._id}`).subscribe(result => {
        this.data = result;
        loading.dismiss();
      })
    }, 300)
  }

  async reloadAuctionOrder() {
    const text = await this._translate.get('loading_controller_text').toPromise();
    const loading = await this._loading.create({
      message: text,
    });
    loading.present();
    setTimeout(() => {
      this._http.get<any>(`${environment.api_url}/auction/${this.data._id}`).subscribe(result => {
        this.data = result;
        loading.dismiss();
      })
    }, 300)
  }
}
