<div class="container-fluid gx-0">
  <form [formGroup]="group" style="overflow-x:hidden;">
    <div formArrayName="crates" style="overflow-x: hidden;">
      <div class="row w-100 gx-2 mt-4 pt-2" *ngFor="let crates of getControls(); let i = index" [formGroupName]="i"
        style="overflow-x: hidden;">
        <div class="col-xl-2 col-lg-4 col-md-4 col-6 mt-1" style="overflow-x: hidden;">
          <mat-form-field appearance="outline" class="full-width pt-1" style="overflow-x: hidden;">
            <mat-label translate>quantity</mat-label>
            <input matInput type="number" formControlName="quantity" />
            <span matTextSuffix translate [ngStyle]="{'color': isIframe ? '#bbb' : 'inherit'}">pcs</span>
          </mat-form-field>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-6 mb-1 mt-1" style="overflow-x: hidden;">
          <mat-form-field appearance="outline" class="full-width pt-1">
            <mat-label translate>packaging</mat-label>
            <mat-select (valueChange)="updateLengthAndWidth($event, i)" formControlName="packaging"
              [style.color.white]="isIframe">
              <mat-option *ngFor="let option of options" [value]="option.name"><span
                  translate>{{option.name}}</span></mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-6 mb-1 mt-1" style="overflow-x: hidden;">
          <mat-form-field appearance="outline" class="full-width pt-1">
            <mat-label translate>length</mat-label>
            <input matInput type="number" formControlName="length" />
            <span matTextSuffix translate [ngStyle]="{'color': isIframe ? '#bbb' : 'inherit'}">cm</span>
            <mat-error *ngIf="group.get(['crates', i, 'length'])?.hasError('max')" translate>
              maximum_length_custom
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-6 mb-1 mt-1" style="overflow-x: hidden;">
          <mat-form-field appearance="outline" class="full-width pt-1">
            <mat-label translate>wide</mat-label>
            <input matInput type="number" formControlName="wide" />
            <span matTextSuffix translate [ngStyle]="{'color': isIframe ? '#bbb' : 'inherit'}">cm</span>
            <mat-error *ngIf="group.get(['crates', i, 'wide'])?.hasError('max')" translate>
              maximum_wide_custom
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-6 mb-1 mt-1" style="overflow-x: hidden;">
          <mat-form-field appearance="outline" class="full-width pt-1">
            <mat-label translate>height</mat-label>
            <input matInput type="number" formControlName="height" />

            <span matTextSuffix translate [ngStyle]="{'color': isIframe ? '#bbb' : 'inherit'}">cm</span>
            <mat-error *ngIf="!individual" translate>package_too_high</mat-error>

          </mat-form-field>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-6 mb-1" style="overflow-x: hidden;">
          <div class="row pt-1">
            <div [ngClass]="getControls().length > 1 ? 'col-8' : 'col-12'">
              <mat-form-field appearance="outline" class="full-width pt-1">
                <mat-label translate>weight</mat-label>
                <input matInput formControlName="weight" mask="separator" thousandSeparator="." />
                <span matTextSuffix translate [ngStyle]="{'color': isIframe ? '#bbb' : 'inherit'}">kg</span>
                <mat-error *ngIf="!individual && group.get('weight')?.hasError('max')" translate
                  [translateParams]="{value: currentOption.maxWeight ?? 1000}">package_too_heavy</mat-error>
                <mat-hint *ngIf="currentOption?.maxWeight">Max: {{ currentOption.maxWeight }}kg</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-4 col-4" align="left" style="margin-left: -12px;" *ngIf="getControls().length > 1">
              <ion-button style="--background: transparent" class="mt-3" fill="none" color="error"
                (click)="removeOrClearCrates(i)">
                <ion-icon [ngStyle]="{'color': isIframe ? 'white' : 'inherit'}" name="remove-outline"></ion-icon>
              </ion-button>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12" style="overflow-x: hidden;">
          <ion-item lines="none" style="--background: transparent;">
            <ion-toggle color="light" slot="start" formControlName="stackable"></ion-toggle>
            <ion-label>
              <span translate [ngStyle]="{'color': isIframe ? 'white' : 'inherit'}">stackable_loadable</span>
              <ion-icon [matTooltip]="(individual ? 'stackable_tooltip_individual' : 'stackable_tooltip') | translate"
                class="ms-2" name="information-circle-outline"
                [ngStyle]="{'color': isIframe ? 'white' : 'inherit'}"></ion-icon>
            </ion-label>
          </ion-item>
        </div>
      </div>
    </div>
  </form>

  <div class="row mt-2" style="overflow-x: hidden;">
    <div class="col-lg-4 col-12">
      <ion-button class="full-width" color="light" (click)="addCrateGroup()">
        <ion-icon slot="start" name="add-outline"></ion-icon>
        <ion-label translate>add_package</ion-label>
      </ion-button>
      <ion-button class="full-width" color="light" *ngIf="getControls().length > 1" (click)="removeOrClearCrates()">
        <ion-icon slot="start" name="remove-outline"></ion-icon>
        <ion-label translate>remove_package</ion-label>
      </ion-button>
    </div>
    <div class="col-lg-8 col-12 text-center" style="overflow-x: hidden;">
      <p translate [ngStyle]="{'color': isIframe ? 'white' : 'inherit'}">each_incl_packaging</p>
      <hr />

      <div class="row" style="overflow-x: hidden;">
        <div class="col-4">
          <b translate [ngStyle]="{'color': isIframe ? 'white' : 'inherit'}">packages</b><br />
          <span [ngStyle]="{'color': isIframe ? 'white' : 'inherit'}">{{ package_data.packages }}</span>
        </div>
        <div class="col-4">
          <b translate [ngStyle]="{'color': isIframe ? 'white' : 'inherit'}">loading_metre

          </b><br />
          <span [ngStyle]="{'color': isIframe ? 'white' : 'inherit'}">{{ (package_data.loading_meter).toFixed(2) }}
            ldm</span>
        </div>
        <div class="col-4">
          <b translate [ngStyle]="{'color': isIframe ? 'white' : 'inherit'}">total_weight</b><br />
          <span [ngStyle]="{'color': isIframe ? 'white' : 'inherit'}">{{ package_data.total_weight }} kg</span>
        </div>
      </div>
    </div>
    <div class="col-12 mt-4" style="overflow-x: hidden;">
      <div class="alert alert-warning" *ngIf="checkIfToHigh()">
        <b>
          <ion-icon name="warning-outline"></ion-icon>
          <span class="ms-2" translate [innerHTML]="'to_high_or_to_heavy_headline' | translate"></span>
        </b>
        <p translate [innerHTML]="'to_high_alert' | translate"></p>
      </div>
      <div class="alert alert-warning" *ngIf="checkIfToHeavy()">
        <b>
          <ion-icon name="warning-outline"></ion-icon>
          <span class="ms-2" translate [innerHTML]="'to_high_or_to_heavy_headline' | translate"></span>
        </b>
        <p translate [innerHTML]="'to_heavy_alert' | translate"></p>
      </div>

    </div>
  </div>