import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthGuard, AuthVerificationGuard } from './guards/auth/auth.guard';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { SharedModule } from './shared.module';
import { HttpAppInterceptor } from './interceptor/http-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddressService } from './services/address-service/address-service.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { NgxGdprCookieConsentModule } from 'ngx-gdpr-cookie-consent';

import { NgxMaskModule } from 'ngx-mask';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(), HttpClientModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)), provideAuth(() => getAuth()), provideFirestore(() => getFirestore()), provideStorage(() => getStorage()),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SharedModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    NgxGdprCookieConsentModule.forRoot({
      cookieTypes: [
        {
          id: 'essential',
          name: 'Essenzielle Cookies',
          description: 'Essenzielle Cookies werden benötigt um Stabilität und Leistung sicherzustellen',
          disabled: true,
          selected: true
        },
        {
          id: 'external',
          name: 'Externe Dienste Services',
          description: 'Externe Dienste helfen uns dir ein besseres Erlegnis zu bieten',
          scripts: [
            {
              url: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAwhryWVKLpMSL-kq1PfykWDhwvo8wfPJw&libraries=places&callback=Function.prototype',
              async: true
            }
          ]
        }
      ]
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthGuard,
    AuthVerificationGuard,
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAppInterceptor, multi: true },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    AddressService,
    AuthService,
    UserService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
