<div class="container">
    <div class="chat">

        <div class="messages" #chat id="chat">


            <div *ngFor="let message of messages">
                <div class="message" [ngClass]="message.createdBy == message.userId ? 'parker' : 'stark'">
                    {{message.message}}</div>
            </div>
        </div>
        <div *ngIf="isWriting" class="message stark">
            <div class="typing typing-1"></div>
            <div class="typing typing-2"></div>
            <div class="typing typing-3"></div>
        </div>
    </div>

    <div class="input-container p-4" align="center">
        <mat-progress-bar *ngIf="isWriting" mode="indeterminate"></mat-progress-bar>
        <div class="input-container-inner">
            <input [(ngModel)]="message" [disabled]="isWriting" placeholder="Deine Frage...." type="text"
                (keyup.enter)="sendMessage()" />
            <mat-icon *ngIf="!isWriting && message" (click)="sendMessage()">send</mat-icon>
        </div>

    </div>
</div>