import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MapsService } from 'src/app/services/maps/maps.service';
import { UserInformationDialogComponent } from '../../user-information-dialog/user-information-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-direct-content',
  templateUrl: './direct-content.component.html',
  styleUrls: ['./direct-content.component.scss'],
})
export class DirectContentComponent implements OnInit, AfterViewInit {

  @Input()
  data: any;

  @ViewChild('map', { static: true }) mapElementRef!: ElementRef;
  googleMaps: any;
  distance: any;
  directionsService: any;
  directionsDisplay: any;
  insuranceLoading = false;

  constructor(private renderer: Renderer2, private maps: MapsService, private _http: HttpClient, private dialog: MatDialog) { }

  ngAfterViewInit() {
    this.loadMap()
  }
  ngOnInit() { }

  async loadMap() {
    try {
      const elem = document.getElementById(`map`);

      const sourceLat = this.data.address.pickup.location[0];
      const sourceLng = this.data.address.pickup.location[1];
      const destLat = this.data.address.deliver.location[0];
      const destLng = this.data.address.deliver.location[1];

      let googleMaps: any = await this.maps.loadGoogleMaps();
      const mapEl = elem;
      const map = new googleMaps.Map(mapEl, {
        center: { lat: sourceLat, lng: sourceLng },
        disableDefaultUI: true,
        zoom: 10,
      });
      this.directionsService = new googleMaps.DirectionsService;
      this.directionsDisplay = new googleMaps.DirectionsRenderer;
      this.directionsDisplay = new googleMaps.DirectionsRenderer();

      const source_position = new googleMaps.LatLng(sourceLat, sourceLng);
      const destination_position = new googleMaps.LatLng(destLat, destLng);

      const source_marker = new googleMaps.Marker({
        map: map,
        position: source_position,
        animation: googleMaps.Animation.DROP
      });

      const destination_marker = new googleMaps.Marker({
        map: map,
        position: destination_position,
        animation: googleMaps.Animation.DROP,
      });

      source_marker.setMap(map);
      destination_marker.setMap(map);

      this.directionsDisplay.setMap(map);
      this.directionsDisplay.setOptions({
        polylineOptions: {
          strokeWeight: 3,
          strokeOpacity: 1,
          strokeColor: '#7b213e'
        },
        suppressMarkers: true
      });

      await this.drawPolyline();

      map.setCenter(source_position);
      this.renderer.addClass(mapEl, 'visible');
    } catch (e) {
    }
  }

  drawPolyline() {
    const sourceLat = this.data.address.pickup.location[0];
    const sourceLng = this.data.address.pickup.location[1];
    const destLat = this.data.address.deliver.location[0];
    const destLng = this.data.address.deliver.location[1];
    const source = { lat: sourceLat, lng: sourceLng };
    const dest = { lat: destLat, lng: destLng };
    this.directionsService.route({
      origin: source,
      destination: dest,
      travelMode: 'DRIVING',
      provideRouteAlternatives: true
    }, (response: any, status: any) => {
      if (status === 'OK') {
        this.directionsDisplay.setDirections(response);
        const directionsData = response.routes[0].legs[0];
        const duration = directionsData.duration.text;
      }
    });
  }

  getType(shipment: any) {
    if (shipment.selectedTab == 0) {
      return 'palet_box_tab';
    } else if (shipment.selectedTab == 1) {
      return 'request_based_loading_meter';
    }
    return 'request_based_vehicle_type';
  }

  acceptOffer() {

  }

  includesOption(options: any[], option: any) {
    const index = options.findIndex((object: any) => object.name === option);
    return index !== -1;
  }

  downloadInsuranceDocs() {
    this.insuranceLoading = true;
    const transportInsurance = this.data.additional_information.additional_options.filter((option: any) => option.name == 'transportinsurance')[0]
    this._http.get<any>(`${environment.api_url}/insurance/${transportInsurance.insuranceId}/documents`).subscribe(response => {
      this.insuranceLoading = false;
      if (response.status === 'success' && response.documents) {
        response.documents.forEach((document: any) => {
          if (document.data) {
            const pdfBlob = this.base64ToBlob(document.data, 'application/pdf');
            this.saveBlob(pdfBlob, document.filename);
          }
        });
      } else {
        this.showErrorDialog();
      }
    }, error => {
      this.insuranceLoading = false;
      this.showErrorDialog();
    });
  }
  showErrorDialog() {
    this.dialog.open(UserInformationDialogComponent, {
      width: "400px", data: {
        headline: 'no_insurance_docs_headline',
        body: 'no_insurance_docs_body'
      }
    });
  }

  private base64ToBlob(base64: string, mimeType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  private saveBlob(blob: Blob, filename: string): void {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.download = filename;
    link.click();

    URL.revokeObjectURL(url);
  }

  getValueForAdditionalOption(option: string) {        
    if (this.data.additional_information.additional_options.filter((object: any) => object.name == option).length > 0) {      
      return this.data.additional_information.additional_options.filter((object: any) => object.name == option)[0]
    }
    return {}
  }
}

