import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import dayjs from 'dayjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements AfterViewInit {
  @ViewChild('statusChart')
  private revenueChartRef: ElementRef | undefined;
  private revenueChart: any;

  timeframe = 'today';

  constructor(private _translate: TranslateService,
    private _http: HttpClient) { }

  @Input()
  path!: string;

  @Input()
  logistic = false;

  updateView(statusData: number[]) {
    this.revenueChart.data.datasets[0].data = statusData
    this.revenueChart.update();
  }

  ngAfterViewInit(): void {
    if (this.logistic) {
      this.initLineLogistic();
    } else {
      this.initLineCustomer();
    }
  }

  async initLineLogistic() {
    const offerAccepted = await this._translate.get('offer_created').toPromise();
    const offerRejected = await this._translate.get('offer_negotiate').toPromise();
    const offerExpried = await this._translate.get('offer_revoked_cancelled').toPromise();
    const labels = [offerAccepted, offerRejected, offerExpried]
    this.initLine(labels);
  }

  async initLineCustomer() {
    const offerAccepted = await this._translate.get('offer_accepted').toPromise();
    const offerRejected = await this._translate.get('offer_declined').toPromise();
    const offerExpried = await this._translate.get('offer_expired').toPromise();
    const labels = [offerAccepted, offerRejected, offerExpried];
    this.initLine(labels);
  }

  async initLine(labels: string[]) {
    setTimeout(() => {
      const data = {
        labels: labels,
        datasets: [
          {
            data: {},
            backgroundColor: ['green', '#7b213e', '#102045']
          },
        ]
      };

      this.revenueChart = new Chart(this.revenueChartRef!.nativeElement, {
        type: 'bar',
        data: data,
        options: {
          indexAxis: 'y',
          elements: {
            bar: {
              borderWidth: 0
            }
          },
          scales: {
            x: {
              ticks: {
                precision: 0
              }
            }
          },
          responsive: true,
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: false,
            }
          }
        },
      });
      this.loadData();
    }, 200)
  }

  updateTimeframe(timeframe: string) {
    this.timeframe = timeframe;
    this.loadData();
  }

  getTimeFrame() {
    switch (this.timeframe) {
      case 'yesterday':
        return {
          start: dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
      case 'last_week':
        return {
          start: dayjs().subtract(1, 'week').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
      case 'last_30_days':
        return {
          start: dayjs().subtract(30, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
      default:
        return {
          start: dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
    }
  }

  async loadData() {
    try {
      const timeframe = this.getTimeFrame();
      const result = await this._http.get<any>(`${environment.api_url}${this.path}?start=${timeframe.start}&end=${timeframe.end}`).toPromise();

      if (this.logistic) {
        this.updateView([
          result.offer_created,
          result.price_negotiate,
          result.offer_revoked
        ]);
      } else {
        this.updateView([
          result.offer_accepted,
          result.offer_declined,
          result.offer_revoked
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
