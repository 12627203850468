import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { take, switchMap } from 'rxjs/operators';


@Injectable()
export class HttpAppInterceptor implements HttpInterceptor {
    constructor(private _auth: AngularFireAuth) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this._auth.idToken.pipe(
            take(1),
            switchMap((token: any) => {                
                if (token) {   
                    request = request.clone({
                        setHeaders: { Authorization: `Bearer ${token}` }
                    });
                }
                return next.handle(request);
            })
        );
    }
}