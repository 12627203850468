<div class="inner-content">
  <ion-header>
    <ion-toolbar>
      <ion-title translate>import_in_progress</ion-title>
      <ion-spinner *ngIf="isLoading" slot="end" name="crescent" class="me-2"></ion-spinner>
    </ion-toolbar>
  </ion-header>

  <div class="m-3 pt-2 pb-2">
    <ion-progress-bar [value]="progressValue"></ion-progress-bar>
    <div class="text-end mt-2">
      <i>{{importedItems}} <span translate>of</span> {{totalItems}} <span translate>skipped</span>: {{skippedItems}}</i>
    </div>
  </div>
</div>