import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-download-kravag-thing',
  templateUrl: './download-kravag-thing.component.html',
  styleUrls: ['./download-kravag-thing.component.scss'],
})
export class DownloadKravagThingComponent implements OnInit {
  
  downloadForm() {
    window.open('https://www.kravag.de/dam/jcr:745b1e52-e5e3-4bd2-8f03-58827285de26/kravag_schadenanzeige_verkehrshaftung.pdf', '_blank')
  }

  constructor() { }

  ngOnInit() { }


  @Input()
  insuranceDetails!: any;

  @Input()
  orderId!: string;

  @Output()
  kravagDownloaded: EventEmitter<string> = new EventEmitter();

  @Output()
  goBack: EventEmitter<any> = new EventEmitter();

  goToLegal() {
    this.kravagDownloaded.emit('yes')
  }
  goBackToDetails() {
    this.goBack.emit();
  }
}
