import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ModalController } from '@ionic/angular';
import { DataVerificationRequiredModalComponent } from 'src/app/components/data-verification-required-modal/data-verification-required-modal.component';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  constructor(private _modal: ModalController, private _store: AngularFirestore) { }
  public async checkVerification(user?: firebase.default.User | null) {
    if(!user) return;    
    const idToken = await user.getIdTokenResult(true);
    
    if (idToken.claims['isVerified']) {
      return true;
    }

    const profile = await this._store.collection('users').doc<any>(user.uid).get().toPromise();    
    if ([ 'verified'].includes(profile.data().verification?.status) || profile.data().accountType != 'logistic') {
      return true;
    }
    
    const modal = await this._modal.create({
      component: DataVerificationRequiredModalComponent,
      backdropDismiss: false,
      cssClass: 'auto-height'
    });
    
    await modal.present();
    return false
  }

}
