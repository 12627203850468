<div class="dropzone mt-3 mb-3" [class.disabled-zone]="disabled" appDnd (fileDropped)="onFileDropped($event)"
  *ngIf="(!isUploading && !isUploaded) || disabled">
  <input type="file" [hidden]="disabled" #fileDropRef id="fileDropRef" [accept]="acceptFilter"
    (change)="fileBrowseHandler($event)" />
  <h3 class="fileover-title" translate>drag_and_drop_release</h3>
  <h3>{{ title | translate }}</h3>
  <h3>oder</h3>
  <label for="fileDropRef" class="mb-3" translate>select_file</label><br />
</div>

<div class="dropzone mt-3 mb-3" *ngIf="isUploaded && !disabled">
  <h3 class="fileover-title" translate>preview</h3>
  <button class="mb-3" (click)="openPreview()" translate>view_preview</button><br />
  <h3>oder</h3>
  <button class="mb-3" (click)="resetForm()" translate>reset</button>
</div>

<div class="loading-overlay" *ngIf="isUploading && !disabled">
  <ion-spinner name="crescent" color="secondary"></ion-spinner>
  <div class="progress-bar-overlay">
    <ion-progress-bar color="primary" [value]="progress"></ion-progress-bar>
  </div>
</div>