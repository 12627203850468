import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ask-user-dialog',
  templateUrl: './ask-user-dialog.component.html',
  styleUrls: ['./ask-user-dialog.component.scss'],
})
export class AskUserDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AskUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() { }

}
