import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-producsts-container',
  templateUrl: './top-producsts-container.component.html',
  styleUrls: ['./top-producsts-container.component.scss'],
})
export class TopProducstsContainerComponent implements OnInit {

  @Input()
  path!: string;

  isLoading = true;

  timeframe = 'today';
  topProductsData = {
    direct: {
      total: 0,
      count: 0
    },
    auction: {
      total: 0,
      count: 0
    },
    anonymous: {
      total: 0,
      count: 0
    }
  }

  constructor(private _http: HttpClient) { }

  ngOnInit() {
    this.loadTopProducts();
  }

  updateTimeframe(timeframe: string) {
    this.timeframe = timeframe;
    this.loadTopProducts();
  }

  getTimeFrame() {
    switch (this.timeframe) {
      case 'yesterday':
        return {
          start: dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
      case 'last_week':
        return {
          start: dayjs().subtract(1, 'week').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
      case 'last_30_days':
        return {
          start: dayjs().subtract(30, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
      default:
        return {
          start: dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
    }
  }

  async loadTopProducts() {
    this.isLoading = true;
    try {
      const timeframe = this.getTimeFrame();
      this.topProductsData = await this._http.get<any>(`${environment.api_url}/${this.path}?start=${timeframe.start}&end=${timeframe.end}`).toPromise()
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
