import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import dayjs from 'dayjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cancel-order-dialog',
  templateUrl: './cancel-order-dialog.component.html',
  styleUrls: ['./cancel-order-dialog.component.scss'],
})
export class CancelOrderDialogComponent implements OnInit {
  states = ["status_auction_cancelled_free", "status_auction_cancelled_paid"]
  reasons = ['changes_requested', 'not_enough_information', 'storno', 'any_other_reason'];
  isLoading = false;
  @Input()
  offer: any;

  @Input()
  headline: string | undefined;

  @Input()
  body: string | undefined;

  @Input()
  isSupplier: boolean = false;

  group: FormGroup = this._builder.group({
    reason: [null, [Validators.required]],
    message: [null, []],
    state: [{ disabled: true, value: 'status_auction_cancelled_free' }, [Validators.required]]
  });

  constructor(private _modal: ModalController,
    private _builder: FormBuilder, private http: HttpClient) { }

  ngOnInit() {
    if (this.isSupplier) {
      this.reasons = ['cancellation_logistic_changes_in_disposition', 'cancellation_logistic_no_rest_times', 'cancellation_logistic_no_time', 'any_other_reason']
    }
    const now = dayjs();
    const dueDate = dayjs(this.offer.rawData.additional_information.start_date);

    if (dueDate.diff(now, "hour") <= 24) {
      this.group.controls['state'].setValue("status_auction_cancelled_paid");
    } else {
      this.group.controls['state'].setValue("status_auction_cancelled_free");
    }
  }

  close() {
    this._modal.dismiss();
  }

  cancelOrder() {
    this.isLoading = true;
    let url = `${environment.api_url}/direct/${this.offer.rawData._id}/cancel?reason=${this.group.get('reason')!.value}&message=${this.group.get('message')!.value}`
    if (this.offer.isSupplier) {
      url = `${environment.api_url}/direct/${this.offer.rawData._id}/supplier/cancel?reason=${this.group.get('reason')!.value}&message=${this.group.get('message')!.value}`
    }
    this.http.delete<any>(url,).subscribe(async result => {
      this.isLoading = false;
      this._modal.dismiss();
    }, error => {
      if (confirm('Dieser Auftrag konnte nicht storniert werden')) {
        this._modal.dismiss();
      }
    })
  }

}