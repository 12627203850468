import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bet-too-low-dialog',
  templateUrl: './bet-too-low-dialog.component.html',
  styleUrls: ['./bet-too-low-dialog.component.scss'],
})
export class BetTooLowDialogComponent implements OnInit {
  message = '';
  constructor(private dialogRef: MatDialogRef<BetTooLowDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private _translate: TranslateService) { }

  ngOnInit() {
    this.getMessage();
  }
  async getMessage() {
    if (this.data.isExpired) {
      this.message = await this._translate.get('invalid_bet_body_expired').toPromise();
    } else {
      this.message = await this._translate.get('invalid_bet_body').toPromise();
    }
  }

}
