import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { NavController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { VerificationService } from 'src/app/services/verification/verification.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {

  currentLang = 'de';
  menuExpanded = false;

  @Input()
  activePage = 'order';

  signed_in_mail = '';

  constructor(private _translate: TranslateService,
    private _auth: AngularFireAuth,
    private _router: Router,
    private _verification: VerificationService,
    private _authService: AuthService,
    private _nav: NavController,
    private _store: AngularFirestore,
    private popover: PopoverController,
    public _user: UserService) { }

  ngOnInit() {
    this.getLang();

    this._auth.authState.subscribe(result => {
      if (result?.email) {
        this.signed_in_mail = result.email;
      }
    });
  }

  async logout() {
    await this._authService.logout();
    this._nav.navigateRoot('/');
  }

  async getLang() {
    this.currentLang = (await Preferences.get({ key: 'language' })).value ?? 'de';
  }

  async setLang(lang: string) {
    this.currentLang = lang;
    this._translate.use(lang);
    this._translate.reloadLang(lang);
    Preferences.set({ key: 'language', value: lang });

    const user = await this._auth.currentUser;
    this._store.collection('users').doc(user?.uid).update({
      regional: {
        language: lang
      }
    });
  }

  async navigate(page: string, isSupplier = false) {
    this.popover.dismiss();
    if (isSupplier) {
      const user = await this._auth.authState.pipe(take(1)).toPromise();
      if (await this._verification.checkVerification(user)) {
        this._router.navigate([page]);
      }
    } else {
      this._router.navigate([page]);
    }

  }
  openLink(link: string) {
    window.open(link, '_blank')
  }

  openFAQ() {
    if (this._user.isLogistic()) {
      window.open('https://vehar.zendesk.com/hc/de/categories/16707736865298-Lieferanten-Helpcenter', '_blank');
      return;
    }
    window.open('https://vehar.zendesk.com/hc/de/categories/16707738279314-Kunden-Helpcenter', '_blank');
  }
}
