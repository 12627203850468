<h2 translate>kravag_form_headline</h2>

<div class="container">
  <div class="row pt-1">
    <div class="col-12" [innerHTML]="'kravag_form_body' | translate"></div>
    <div align="center" class="mt-3" color="secondary">
      <ion-button translate (click)="downloadForm()">download_kravag_doc</ion-button>
    </div>
    <div class="col-12 mt-2 clearfix">
      <button class="float-stat" mat-button (click)="goBackToDetails()">{{'back' | translate}}</button>

      <ion-button (click)="goToLegal()" color="secondary" class="float-end next" >
        <ion-label translate>next</ion-label>
        <ion-icon name="arrow-forward-outline" slot="end"></ion-icon>
      </ion-button>
    </div>
  </div>
</div>