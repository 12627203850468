import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comparisationFormatter'
})
export class ComparisationFormatterPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string[] {
    const values: string[] = value.split(";");
    return values;
  }

}
