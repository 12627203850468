<div *ngIf="data" class="main-container">
  <div class="row mt-2">
    <div class="col-6">
      <ion-card>
        <ion-card-content>
          <div class="heading" translate>request_type</div>
          <div class="content">{{ getType(data) | translate }}</div>
        </ion-card-content>
      </ion-card>

    </div>
    <div class="col-6">
      <ion-card>
        <ion-card-content>
          <div class="heading" translate>distance</div>
          <div class="content">{{data.address?.distance }} Km</div>
        </ion-card-content>
      </ion-card>
    </div>
    <div class="col-12">
      <ion-card>
        <ion-card-content>
          <div class="map" [id]="'map'"></div>
        </ion-card-content>
      </ion-card>
    </div>
    <div class="col-12 col-lg-6 mt-1">
      <ion-card>
        <ion-card-header>
          <ion-card-title translate>pickup</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p>{{ data.address.pickup.companyname }}</p>
          <p>{{ data.address.pickup.contact }}</p>
          <p>{{ data.address.pickup.name }}</p>
          <p>{{ data.address.pickup.street }} {{
            data.address.pickup.housenumber }}</p>
          <p>{{ data.address.pickup.zipcode }}
            {{ data.address.pickup.city }} -
            {{ data.address.pickup.country.toUpperCase() }}
            <img class="ms-2 mb-1" src="assets/countries/{{ (data.address.pickup.country ?? '').toLowerCase() }}.svg"
              width="20" />
          </p>
          <p *ngIf="data.additional_information.pickup_start_date">
            <b translate>pickup_date</b>: <span>{{ data.additional_information.pickup_start_date | date:
              'dd.MM.yyyy'}} - {{
              data.additional_information.pickup_end_date | date:
              'dd.MM.yyyy'}}  {{data.additional_information.start_time }} </span>
          </p>
          <p *ngIf="data.loading_kind">
            <b translate>loading_kind</b>: <span>{{data.loading_kind.loading_kind | translate }} </span>
          </p>
          <p *ngIf="data.address.pickup.reference">
            <b translate>pickup_reference</b>: <span>{{ data.address.pickup.reference}}</span>
          </p>
          <p *ngIf="data.address.pickup.contact">
            <b translate>contact</b>: <span>{{ data.address.pickup.contact}}</span>
          </p>
          <p *ngIf="data.address.pickup.email">
            <b translate>email</b>: <a href="mailto:{{data.address.pickup.email}}">{{
              data.address.pickup.email
              }}</a>
          </p>
          <p *ngIf="data.address.pickup.phone">
            <b translate>phone</b>: <span>{{ data.address.pickup.phone}}</span>
          </p>
          <p *ngIf="data.address.pickup.notes">
            <b translate>additional_notes</b>: <br><span>{{ data.address.pickup.notes }}</span>
          </p>
        </ion-card-content>
      </ion-card>
    </div>
    <div class="col-12 col-lg-6 mt-1">
      <ion-card>
        <ion-card-header>
          <ion-card-title translate>delivery</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p>{{ data.address.deliver.companyname }}</p>
          <p>{{ data.address.deliver.contact }}</p>
          <p>{{ data.address.deliver.name }}</p>
          <p>{{ data.address.deliver.street }} {{
            data.address.deliver.housenumber }}</p>
          <p>{{ data.address.deliver.zipcode }}
            {{ data.address.deliver.city }} -
            {{ data.address.deliver.country.toUpperCase() }}
            <img class="ms-2 mb-1" src="assets/countries/{{ (data.address.deliver.country ?? '').toLowerCase() }}.svg"
              width="20" />
          </p>
          <p *ngIf="data.additional_information.deliver_start_date">
            <b translate>delivery_date</b>: <span>{{ data.additional_information.deliver_start_date | date:
              'dd.MM.yyyy'}} - {{
              data.additional_information.deliver_end_date | date:
              'dd.MM.yyyy'}} {{ data.additional_information.end_time }} </span>
          </p>
          <p *ngIf="data.loading_kind">
            <b translate>unloading_kind</b>: <span>{{data.loading_kind.unloading_kind | translate }} </span>
          </p>
          <p *ngIf="data.address.deliver.reference">
            <b translate>deliver_reference</b>: <span>{{ data.address.deliver.reference}}</span>
          </p>
          <p *ngIf="data.address.deliver.contact">
            <b translate>contact</b>: <span>{{ data.address.deliver.contact}}</span>
          </p>
          <p *ngIf="data.address.deliver.email">
            <b translate>email</b>: <a href="mailto:{{data.address.deliver.email}}">{{
              data.address.deliver.email }}</a>
          </p>
          <p *ngIf="data.address.deliver.phone">
            <b translate>phone</b>: <span>{{ data.address.deliver.phone}}</span>
          </p>
          <p *ngIf="data.address.deliver.notes">
            <b translate>additional_notes</b>: <br><span>{{ data.address.deliver.notes }}</span>
          </p>
        </ion-card-content>
      </ion-card>
    </div>

    <div class="col-12 mt-2">
      <ion-card>
        <ion-card-header>
          <ion-card-title translate>
            packaging
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div class="mt-2">

            <div *ngIf="data.selectedTab == 0">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" translate>quantity</th>
                    <th scope="col" translate>packaging</th>
                    <th scope="col" translate>dimension</th>
                    <th scope="col" translate>weight</th>
                    <th scope="col" translate>stackable_loadable</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let crate of data.crates.crates">
                    <th scope="row">{{ crate?.quantity }}</th>
                    <td>{{ crate?.packaging | translate }}</td>
                    <td>({{ crate?.length }}x{{ crate?.wide }}x{{
                      crate?.height }}cm)</td>
                    <td>{{ crate?.weight }} kg</td>
                    <td *ngIf="crate?.stackable" translate>yes</td>
                    <td *ngIf="!crate?.stackable" translate>no</td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div *ngIf="data.selectedTab == 1">
              <table class="table">
                <tbody>
                  <tr>
                    <th translate>loading_meter</th>
                    <td>{{ data.loading_meter.loading_meter.toFixed(2) }} ldm</td>
                  </tr>
                  <tr>
                    <th translate>total_weight</th>
                    <td> {{ data.loading_meter.weight }} kg</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="data.selectedTab == 2">
              <table class="table">
                <tbody>
                  <tr>
                    <th translate>request_type</th>
                    <td translate>
                      request_based_vehicle_type
                    </td>
                  </tr>
                  <tr>
                    <th translate>vehicle_type</th>
                    <td> <span *ngIf="data.vehicles.vehicleType == 0" translate>sprinter_image</span>
                      <span *ngIf="data.vehicles.vehicleType == 1" translate>truck_75</span>
                      <span *ngIf="data.vehicles.vehicleType == 2" translate>truck_12</span>
                      <span *ngIf="data.vehicles.vehicleType == 3" translate>big_truck</span>
                      <span *ngIf="data.vehicles.vehicleType == 4" translate>big_truck_mega</span>
                      <span *ngIf="data.vehicles.vehicleType == 5" translate>truck_thermo</span>
                      <span *ngIf="data.vehicles.vehicleType == 6" translate>truck_crate</span>
                      <span *ngIf="data.vehicles.vehicleType == 7" translate>truck_coil</span>
                      <span *ngIf="data.vehicles.vehicleType == 8" translate>truck_glider</span>
                      <span *ngIf="data.vehicles.vehicleType == 9" translate>open_truck</span>
                      <span *ngIf="data.vehicles.vehicleType == 10" translate>low_level_truck</span>
                    </td> 
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
    <div class="col-12 mt-2">
      <ion-card>
        <ion-card-header>
          <ion-card-title translate>
            additional_options
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p class="mt-2">
            <b translate>what_is_shipped</b>: <span>{{ data.additional_information.what_send |
              translate }}</span>
          </p>

          <p class="mt-2" *ngIf="data.additional_information.additional_notes">
            <b translate>additional_notes_anonymous</b>: <br><span>{{
              data.additional_information.additional_notes }}</span>
          </p>
          <table class="table">
            <tbody>
              <tr>
                <th translate>pickup_notification</th>
                <td>
                  <span>{{includesOption(data.additional_information?.additional_options, 'pickup_notification')
                    ? 'erforderlich' : 'nicht erforderlich' }}</span>
                </td>
                <td *ngIf="includesOption(data.additional_information?.additional_options, 'transportinsurance')"></td>
              </tr>
              <tr>
                <th translate>delivery_notification</th>
                <td>
                  <span>{{includesOption(data.additional_information?.additional_options, 'delivery_notification')
                    ? 'erforderlich' : 'nicht erforderlich' }}</span>
                </td>
                <td *ngIf="includesOption(data.additional_information?.additional_options, 'transportinsurance')"></td>
              </tr>

              <tr>
                <th translate>timeframe</th>
                <td>
                  <span>{{includesOption(data.additional_information?.additional_options, 'timeframe')
                    ? 'erforderlich' : 'nicht erforderlich' }}</span>
                </td>
                <td *ngIf="includesOption(data.additional_information?.additional_options, 'transportinsurance')"></td>
              </tr>
              <tr>
                <th translate>transportinsurance</th>
                <td>
                  <span>{{includesOption(data.additional_information?.additional_options, 'transportinsurance')
                    ? 'erforderlich' : 'nicht erforderlich' }}</span>
                </td>
                <td *ngIf="includesOption(data.additional_information?.additional_options, 'transportinsurance')">
                  <span *ngIf="!insuranceLoading"> <a class="anchor" (click)="downloadInsuranceDocs()"
                      *ngIf="includesOption(data.additional_information.additional_options, 'transportinsurance')"
                      translate>download_insurance_docs</a></span>
                  <span *ngIf="insuranceLoading"> <ion-spinner color="secondary" slot="end" name="crescent"
                      class="ms-2"></ion-spinner>
                  </span>

                </td>
              </tr>
              <tr>
                <th translate>dangerousgoods</th>
                <td>
                  <span>{{includesOption(data.additional_information?.additional_options, 'dangerousgoods')
                    ? 'vorhanden' : 'kein Gefahrgut' }}</span>
                  <ul class="ms-2"
                    *ngIf="includesOption(data.additional_information?.additional_options, 'dangerousgoods')">
                    <li>
                      <span><span translate>dangerous_goods_class</span>: {{
                        getValueForAdditionalOption('dangerousgoods').dangerous_goods_class
                        }}</span>
                    </li>
                    <li>
                      <span><span translate>packaging_group</span>: {{
                        getValueForAdditionalOption('dangerousgoods').packaging_group
                        }}</span>
                    </li>
                    <li>
                      <span><span translate>packaging_group</span>: {{
                        getValueForAdditionalOption('dangerousgoods').un_number
                        }}</span>
                    </li>
                  </ul>
                </td>
                <td *ngIf="includesOption(data.additional_information?.additional_options, 'transportinsurance')"></td>
              </tr>
              <tr>
                <th translate>pallet_exchange</th>
                <td>
                  <span>{{includesOption(data.additional_information?.additional_options, 'pallet_exchange')
                    ? 'erforderlich' : 'nicht erforderlich' }}</span>
                  <ul class="ms-2"
                    *ngIf="includesOption(data.additional_information?.additional_options, 'pallet_exchange')">
                    <li>
                      <span><span translate>quantity</span>: {{
                        getValueForAdditionalOption('pallet_exchange').qty
                        
                        }}</span>
                    </li>
                  </ul>
                </td>
                <td *ngIf="includesOption(data.additional_information?.additional_options, 'transportinsurance')"></td>
              </tr>
            </tbody>
          </table>
        </ion-card-content>
      </ion-card>
    </div>
  </div>
</div>