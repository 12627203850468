import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vehicle-input',
  templateUrl: './vehicle-input.component.html',
  styleUrls: ['./vehicle-input.component.scss'],
})
export class VehicleInputComponent implements OnInit {

  @Input()
  isIframe = false;
  constructor() { }

  ngOnInit() { }

}
