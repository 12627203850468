<ion-card>
  <ion-card-header>
    <ion-card-title translate>
      status
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <div class="row g-1">
      <div class="col-12">
        <div class="top-right flex-container-button-right">
          <div (click)="updateTimeframe('today')" class="selectionButton"
            [ngClass]="timeframe == 'today' ? 'selectionButton active' : 'selectionButton'" translate>
            today
          </div>
          <div (click)="updateTimeframe('yesterday')" class="selectionButton"
            [ngClass]="timeframe == 'yesterday' ? 'selectionButton active' : 'selectionButton'" translate>
            yesterday
          </div>
          <div class="selectionButton" (click)="updateTimeframe('last_week')"
            [ngClass]="timeframe == 'last_week' ? 'selectionButton active' : 'selectionButton'" translate>
            last_week
          </div>
          <div class="selectionButton" (click)="updateTimeframe('last_30_days')"
            [ngClass]="timeframe == 'last_30_days' ? 'selectionButton active' : 'selectionButton'" translate>
            last_30_days
          </div>
        </div>
      </div>
    </div>
    <div class="full-width mt-2" style="margin-top: 40px !important;">
      <canvas id="statusChart" height="80px" #statusChart></canvas>
    </div>
  </ion-card-content>
</ion-card>