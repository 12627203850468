import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { BehaviorSubject } from 'rxjs';
dayjs.extend(weekday);
@Injectable({
  providedIn: 'root'
})
export class DateService {
  distance = new BehaviorSubject(0);
  getStartDateForPlan3(date: Date) {
    let startDate = dayjs(date);
    while (this.isPubicHoliday(startDate.toDate())) {
      startDate = startDate.add(1, 'day')
    }
    if (startDate.day() == 6 || startDate.day() == 0) {
      startDate = startDate.weekday(+8);
    }
    return startDate.toDate();
  }
  getEndDateForPlan1Date(date: Date) {
    const start = date;
    let endDate = dayjs(start).hour(0);
    for (let i = 0; i < 3; i++) {
      endDate = dayjs(endDate.toDate()).add(1, 'days');
      while (this.isPubicHoliday(endDate.toDate()) || endDate.day() == 6 || endDate.day() == 0) {
        endDate = endDate.add(1, 'day');
      }
    }
    return endDate.toDate();
  }
  getStartDateForPlan1(date: Date) {
    const start = date;
    let startDate = dayjs(start).hour(0);
    return startDate.toDate();
  }

  getEndDateForPlan3Date(date: Date) {
    let startDate = dayjs(date).startOf('d').add(1, 'day');

    while (this.isPubicHoliday(startDate.toDate()) || startDate.day() == 0 || startDate.day() == 6) {
      startDate = startDate.add(1, 'day')
    }

    let endDate = dayjs(startDate);

    return endDate.toDate();

  }

  getEndDateForPlan2Date(date: Date, distance: number): any {
    const now = dayjs();
    let endDate;

    // Prüfe, ob das gegebene Datum heute ist
    if (date.getDate() === now.toDate().getDate() && date.getMonth() === now.toDate().getMonth()) {
      endDate = dayjs(date).add(1, 'day'); // Beginne mit dem nächsten Tag
    } else {
      endDate = dayjs(date).add(1, 'day'); // Beginne ebenfalls mit dem nächsten Tag
    }

    // Wenn die Distanz 80 oder mehr beträgt, füge einen zusätzlichen Tag hinzu
    if (distance >= 80) {
      endDate = endDate.add(1, 'day');
    }

    if (dayjs(date).day() === 5) {
      endDate = dayjs(date).add(4, 'days');
    }

    while (this.isPubicHoliday(endDate.toDate()) || endDate.day() === 6 || endDate.day() === 0) {
      endDate = endDate.add(1, 'day');
    }

    return endDate.toDate();
  }
  getStartDateForPlan2(date: Date, distance: number): any {
    const now = dayjs();
    if (date.getDate() == now.toDate().getDate() && date.getMonth() == now.toDate().getMonth()) {
      if (distance < 80) {
        const startDate = date;
        return startDate;
      } else {
        return date;
      }
    } else {
      if (distance < 80) {
        const startDate = date;
        return startDate;
      } else {
        return date;
      }
    }
  }

  constructor() { }

  isPubicHoliday(date: Date) {
    if (!date) return false;

    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();


    if (year === 2024) {

      if (month === 0 && day === 1) return true;

      if (month === 4 && day === 1) return true;

      if (month === 9 && day === 3) return true;

      if (month === 11 && day === 25) return true;

      if (month === 11 && day === 26) return true;
    }

    const goodFriday = new Date(this.calculateEasterDate(2024));
    goodFriday.setDate(goodFriday.getDate() - 2);
    if (month === goodFriday.getMonth() && day === goodFriday.getDate()) return true;

    const easterMonday = new Date(this.calculateEasterDate(2024));
    easterMonday.setDate(easterMonday.getDate() + 1);
    if (month === easterMonday.getMonth() && day === easterMonday.getDate()) return true;

    const ascensionDay = new Date(this.calculateEasterDate(2024));
    ascensionDay.setDate(ascensionDay.getDate() + 39);
    if (month === ascensionDay.getMonth() && day === ascensionDay.getDate()) return true;


    const pentecostMonday = new Date(this.calculateEasterDate(2024));
    pentecostMonday.setDate(pentecostMonday.getDate() + 50);
    if (month === pentecostMonday.getMonth() && day === pentecostMonday.getDate()) return true;

    return false;
  }

  calculateEasterDate(year: any) {
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const n0 = (h + l + 7 * m + 114)
    const n = Math.floor(n0 / 31) - 1;
    const p = n0 % 31 + 1;

    return new Date(year, n, p);
  }


  isWeekEnd(date: Date) {
    return date.getDay() !== 0 && date.getDay() !== 6;
  }
  getRangeForPlan1(date: Date) {
    const start = date;
    let startDate = dayjs(start).hour(0);
    let endDate = dayjs(start).hour(0);
    for (let i = 0; i < 3; i++) {
      endDate = dayjs(endDate.toDate()).add(1, 'days');
      while (this.isPubicHoliday(endDate.toDate()) || endDate.day() == 6 || endDate.day() == 0) {
        endDate = endDate.add(1, 'day');
      }
    }
    return this.formatStartAndEndDateToDateRange(startDate.toDate(), endDate.toDate());
  }

  validateStartDate(date: Date, selectedPlan: number, selectedTab: number) {

    const now = new Date()
    if (selectedPlan == 1 || selectedTab == 2) {
      if (now.getHours() >= 12) {
        const inTwoDays = dayjs().add(1, 'day');
        if (inTwoDays.toDate().getTime() > (date ?? new Date()).getTime()) {
          return false
        }
      }
    }
    if (selectedPlan == 2 && selectedTab !== 2) {


      const inTwoDays = dayjs();
      if (inTwoDays.toDate().getTime() > (date ?? new Date()).getTime()) {
        return false
      }

    }

    let tomorrow = dayjs();
    if (selectedPlan == 2) {
      tomorrow = dayjs().hour(0).minute(0).second(0).millisecond(0);
    }
    if (date!.getTime() < tomorrow.toDate().getTime()) {
      return false;
    }
    if (this.isPubicHoliday(date)) {
      return false;
    }

    return date.getDay() !== 0 && date.getDay() !== 6;
  }

  formatStartAndEndDateToDateRange(startDate: Date, endDate: Date) {
    let startDateString = `${startDate.getDate()}`
    if (startDate.getDate() < 9) {
      startDateString = `0${startDate.getDate()}`
    }
    if (startDate.getMonth() + 1 < 9) {
      startDateString = startDateString + `.0${startDate.getMonth() + 1}`
    } else {
      startDateString = startDateString + `.${startDate.getMonth() + 1}.`
    }
    let endDateString = `${endDate.getDate()}`
    if (endDate.getDate() < 9) {

      endDateString = `0${endDate.getDate()}`
    }
    if (endDate.getMonth() + 1 < 9) {
      endDateString = endDateString + `.0${endDate.getMonth() + 1}`
    } else {
      endDateString = endDateString + `.${endDate.getMonth() + 1}`
    }
    endDateString = endDateString + `.${endDate.getFullYear()}`
    return `${startDateString} - ${endDateString}`;
  }

  getEndDateRangeForPlan2(date: Date, distance: number) {
    const isWeekend = (date: any) => {
      return date.day() === 6 || date.day() === 0;  // Samstag oder Sonntag
    };

    let startDate = dayjs(date);
    let endDate = dayjs(date).add(1, 'day');

    if (distance > 80) {
      endDate = endDate.add(1, 'day');
    }

    if (startDate.day() === 5) {

      endDate = startDate.add(4, 'days'); // Füge 4 Tage hinzu, um von Freitag zu Dienstag zu gelangen
    }

    while (this.isPubicHoliday(endDate.toDate()) || isWeekend(endDate)) {
      endDate = endDate.add(1, 'day');
    }

    return this.formatStartAndEndDateToDateRange(startDate.toDate(), endDate.toDate());
  }

  validateAuctionEndingDate(date: Date, pickupDate: Date) {
    let today = dayjs().startOf('day');
    const auctionEnd = dayjs().add(9, 'day').endOf('day');

    if (date.getTime() < today.toDate().getTime()) {
      return false;
    }
    if (date.getTime() > auctionEnd.toDate().getTime()) {
      return false;
    }
    return true;
  }


  getRangeForPlan3(date: Date) {
    let startDate = dayjs(date);



    // Wenn der Starttag ein Wochenende oder Feiertag ist, verschiebe ihn auf den nächsten Werktag
    while (this.isPubicHoliday(startDate.toDate()) || startDate.day() === 6 || startDate.day() === 0) {
      startDate = startDate.add(1, 'day');
    }

    let endDate = dayjs(startDate).add(1, 'day'); // Das Enddatum ist der Tag nach dem Startdatum

    // Wenn das Enddatum auf ein Wochenende oder einen Feiertag fällt, verschiebe es ebenfalls
    while (this.isPubicHoliday(endDate.toDate()) || endDate.day() === 6 || endDate.day() === 0) {
      endDate = endDate.add(1, 'day');
    }


    return this.formatStartAndEndDateToDateRange(startDate.toDate(), endDate.toDate());

  }
  getPickupSlots(pickupDate: any, selectedPlan: number, selectedTab: number, isDirect = false) {
    let timeslots= ['07:00 - 16:00', '08:00 - 12:00', '08:00 - 17:00', '06:00 - 14:00', '09:00 - 15:00', '11:00 - 17:30', '16:00 - 19:00']
    const blackList = ['11:00 - 17:30', '16:00 - 19:00']
    if (selectedTab == 1 || selectedTab == 0) {
      timeslots = ['07:00 - 16:00', '08:00 - 17:00', '06:00 - 14:00', '09:00 - 15:00', '11:00 - 17:30'];
    }

    if(isDirect){
      return timeslots.filter((slot: string) => !blackList.includes(slot))
    }
    return timeslots
  }
}
